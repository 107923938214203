/* swiper */

.ds-swiper {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;     
  box-sizing: content-box;
  list-style: none;
  padding: 0;
  margin: 0;
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  &>a:focus-visible {
    outline-offset: -2px;
  }
}
.swiper-pagination {
  position: relative;
  margin-top: 16px;
  transition: .3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  &.swiper-pagination-horizontal {
    @include flex(flex, center, center);
    gap: 5px;
  }
  &.swiper-pagination-lock {
    display: none;
  }
}
.swiper-pagination-bullet {
  width: rem(8px);
  height: rem(8px);
  margin: rem(-4px);
  border: rem(4px) solid transparent;
  border-radius: 100%;
  background-color: var(--ds-color-fill-inactive);
  background-clip: padding-box;
  box-sizing: content-box;
}
.swiper-pagination-bullet-active {
  background-color: var(--ds-color-primary);
}
.swiper-pagination-clickable {
  .swiper-pagination-bullet {
    cursor: pointer;
  }
}
.swiper-controls {
  position: absolute;
  top: 0;
  z-index: 10;
  .swiper-button-lock {
    display: none;
  }
}