@import '../config/index.scss';

.dsx-Label {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: var(--dsx-form-label-font-size, var(--dsx-font-size-base));
  font-weight: var(--dsx-form-label-font-weight, var(--dsx-font-weight-medium));
  line-height: 1.3;
  &.is-required::after {
    content: '필수입력항목';
    width: rem(5px);
    height: rem(5px);
    margin-top: -2px;
    border-radius: 100%;
    background-color: var(--dsx-color-negative-fill-default);
    text-indent: -999px;
    overflow: hidden;
  }
  .label-badge {
    display: inline-flex;
    padding: var(--dsx-space-2) var(--dsx-space-3);
    font-size: var(--dsx-font-size-small);
    font-weight: var(--dsx-font-weight-medium);
    border-radius: var(--dsx-radius-base);
    background-color: var(--dsx-color-neutral-fill-neutral);
  }
}

.dsx-Form {
  display: flex;
  flex-wrap: wrap;
  .dsx-Form-item {
    width: 100%;
  }
  &.dsx-column-2 {
    .dsx-Form-item {
      width: 50%;
    }
  }
  &.dsx-column-3 {
    .dsx-Form-item {
      width: 33.3%;
    }
  }
  &-header {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    .dsx-IconButton {
      color: var(--dsx-color-normal-icon-neutral);
    }
  }
  &-content {
    position: relative;
    .dsx-w-full + .dsx-w-full {
      margin-top: 8px;
    }
  }

  // form selection component
  .dsx-RadioGroup,
  .dsx-CheckboxGroup {
    min-height: rem(32px);
  }
  .dsx-w-full {
    min-width: auto;
  }
}

.dsx-Form--inline {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  padding: 12px 16px;
  border-radius: var(--dsx-radius-xlarge);
  background: var(--dsx-color-neutral-surface-alternative);
  .dsx-Form-item {
    display: flex;
    flex-wrap: wrap;
    gap: 0 12px;
  }
  .dsx-Form-header {
    line-height: 32px;
    .dsx-Label {
      min-height: rem(32px);
      align-items: center;
    }
  }
}

.dsx-Form--grid {
  border-top: 1px solid var(--dsx-color-neutral-border-neutral);
  .dsx-Form-item {
    flex: 1 auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid var(--dsx-color-neutral-border-neutral);
  }
  .dsx-Form-header {
    width: rem(150px);
    padding: 8px 12px;
    background: var(--dsx-color-neutral-surface-alternative);
    .dsx-Label {
      min-height: rem(32px);
      align-items: center;
    }
  }
  .dsx-Form-content {
    min-width: 1rem; // 깨짐 방지
    flex: 1;
    padding: 8px 12px;
  }
}

.dsx-Form--vertical {
  --dsx-form-label-font-size: var(--dsx-font-size-large);
  --dsx-form-label-font-weight: var(--dsx-font-weight-semibold);
  padding: var(--dsx-space-3) var(--dsx-space-6);
  border: 1px solid var(--dsx-color-neutral-border-alternative);
  border-radius: calc(var(--dsx-radius-base) * 4);
  .dsx-Form-item {
    margin: var(--dsx-space-6) 0;
    padding: 0 var(--dsx-space-3);
  }
  .dsx-Form-header {
    flex-wrap: wrap;
    align-items: center;
    gap: 2px 6px;
    margin-bottom: var(--dsx-space-3);
    .dsx-Text {
      width: 100%;
    }
    .dsx-IconButton {
      margin-left: auto;
    }
  }

  // .dsx-Text--length {
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  // }
  .dsx-Text--length + .dsx-Text--error {
    position: absolute;
    bottom: 0;
  }
  .dsx-ButtonArea {
    margin-top: 12px;
  }
  .dsx-hr {
    margin: 20px 8px;
  }
}

@include mobile {
  .dsx-Form {
    &-item {
      width: 100%;
    }
    &-header {
      width: 100%;
    }
  }
}
