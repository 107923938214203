html,
body,
#root {
  height: 100%;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading .loading-icon {
  width: 100px;
  height: 100px;
}

.chat-view {
  --ds-chat-aside-width: 16.25rem;
  --ds-chat-aside-border-color: var(--ds-color-border-subtle);
  --ds-chat-aside-background-color: var(--ds-color-surface-paper);
  --ds-chat-history-spacing: calc(2rem + 8px);

  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 100%;
  height: 100%;
  overflow: hidden;
}

.view-chat {
  --ds-chat-history-spacing: calc(2rem + 8px);
}

.chat-main {
  position: relative;
  overflow-y: auto;
}
.chat-main::-webkit-scrollbar {
  display: none; /* Chat 영역 스크롤 숨김 */
}
.chat-main-height {
  height: 100%;
}
.chat-aside {
  --ds-chat-aside-spacer: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: var(--ds-chat-aside-width);
  padding: 0 var(--ds-chat-aside-spacer);
  border-right: 1px solid var(--ds-chat-aside-border-color);
  background-color: var(--ds-chat-aside-background-color);
  transition: margin 0.35s ease-in-out;
}
.chat-aside.is-close {
  margin-left: calc(var(--ds-chat-aside-width) * -1);
}
.chat-aside .chat-aside-collapse {
  position: absolute;
  top: 50%;
  right: -1.75rem;
  transform: translateY(-50%);
  z-index: 10;
}

.chat-aside-collapse .collapse-control .move-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 3rem;
  flex-direction: column;
  color: var(--ds-color-normal-fill-default);
  opacity: 0.22;
}
.chat-aside-collapse .collapse-control .move-arrow::before,
.chat-aside-collapse .collapse-control .move-arrow::after {
  content: '';
  width: 0.1875rem;
  height: 0.75rem;
  margin: -0.09375rem 0;
  border-radius: var(--ds-border-radius-max);
  background-color: currentColor;
  transition: all var(--ds-transition-base);
}
.chat-aside-collapse .collapse-control .move-arrow:hover,
.chat-aside-collapse .collapse-control .move-arrow:focus {
  opacity: 1;
}
.chat-aside-collapse .collapse-control .move-arrow:hover::before,
.chat-aside-collapse .collapse-control .move-arrow:focus::before {
  transform: rotate(30deg);
  border-radius: var(--ds-border-radius-max) var(--ds-border-radius-max) 0 0;
}
.chat-aside-collapse .collapse-control .move-arrow:hover::after,
.chat-aside-collapse .collapse-control .move-arrow:focus::after {
  transform: rotate(-30deg);
  border-radius: 0 0 var(--ds-border-radius-max) var(--ds-border-radius-max);
}
.chat-aside-collapse.is-close .collapse-control .move-arrow::before {
  transform: rotate(-30deg);
  border-radius: var(--ds-border-radius-max) var(--ds-border-radius-max) 0 0;
}
.chat-aside-collapse.is-close .collapse-control .move-arrow::after {
  transform: rotate(30deg);
  border-radius: 0 0 var(--ds-border-radius-max) var(--ds-border-radius-max);
}

.chat-room {
  position: relative;
  display: flex;
  flex-direction: column;
  /* width: 47.5rem; */
  width: 50vw;
  min-width: 47.5rem;
  max-width: 71.75rem;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 48px;
  box-sizing: content-box;
}
.chat-room.is-hidden {
  visibility: hidden;
}
.chat-insight {
  margin: auto;
}

/* 스켈레톤 로딩 css 추가 */
.chat-room.ing-skeleton {
  margin: auto;
}

.chat-room .skeleton-loading {
  display: none;
}

.chat-room.ing-skeleton .chat-visual,
.chat-room.ing-skeleton .chat-keyword {
  opacity: 0;
}

.chat-room.ing-skeleton .skeleton-loading {
  display: block;
  /* z-index: 100; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  position: relative;
  margin: 0 auto 160px;
}

.chat-room.ing-skeleton .skeleton-visual,
.chat-room.ing-skeleton .skeleton-title,
.chat-room.ing-skeleton .skeleton-desc,
.chat-room.ing-skeleton .skeleton-keyword .item,
.chat-room.ing-skeleton .skeleton-keyword .control {
  background: linear-gradient(120deg, #f4f4f5 30%, #fcfcfc 39.5%, #fcfcfc 40%, #f4f4f5 48%);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: skeletonLoad 1.3s infinite;
}

.chat-room.ing-skeleton .skeleton-visual {
  width: 8.75rem;
  height: 8.75rem;
  margin: 65px auto 20px;
  border-radius: var(--ds-border-radius-xxl);
}

.chat-room.ing-skeleton .skeleton-title {
  width: 300px;
  height: 47px;
  margin: 0 auto;
  border-radius: var(--ds-border-radius-xl);
}

.chat-room.ing-skeleton .skeleton-desc {
  width: 300px;
  height: 24px;
  margin: 8px auto 0;
  border-radius: var(--ds-border-radius-xl);
}

.chat-room.ing-skeleton .skeleton-keyword {
  max-width: 47.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin: 65px auto 0;
}

.chat-room.ing-skeleton .skeleton-keyword .item {
  width: 205px;
  height: 40px;
  border-radius: var(--ds-border-radius-max);
}

.chat-room.ing-skeleton .skeleton-keyword .item:nth-child(1) {
  width: 275px;
}

.chat-room.ing-skeleton .skeleton-keyword .item:nth-child(2) {
  width: 267px;
}

.chat-room.ing-skeleton .skeleton-keyword .item:nth-child(3) {
  width: 229px;
}

.chat-room.ing-skeleton .skeleton-keyword .item:nth-child(4) {
  width: 205px;
}

.chat-room.ing-skeleton .skeleton-keyword .control {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

@keyframes skeletonLoad {
  100% {
    background-position: -100% 0;
  }
}

.chat-visual {
  padding: 65px 0;
  text-align: center;
}
.chat-visual .visual {
  height: 8.75rem;
  margin-bottom: 20px;
}
.chat-visual .visual video {
  height: 100%;
  aspect-ratio: 1;

  mask-image: radial-gradient(white, black); /* 표준 속성 */
  -webkit-mask-image: -webkit-radial-gradient(circular, white 0%, black 100%);

  backface-visibility: hidden; /* 여러 브라우저에서 호환 가능하게 추가 */
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.chat-visual .title {
  margin: 0;
  color: var(--ds-color-text-strong);
  font-size: var(--ds-font-size-display1);
  font-weight: var(--ds-font-weight-normal);
}
.chat-visual .title strong {
  font-weight: var(--ds-font-weight-bold);
}
.chat-visual .desc {
  margin: 8px 0 0 0;
  color: var(--ds-color-text-subtle);
  font-size: var(--ds-font-size-lg);
}

.chat-keyword {
  max-width: 47.5rem;
  margin: 0 auto 160px;
}
/* .chat-starters .chat-keyword {
  margin: 0;
  display: flex;
  padding-left: var(--ds-chat-history-spacing);
  font-size: var(--ds-font-size-lg);
  line-height: var(--ds-line-height-reading);
} */
.chat-keyword .keyword-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
.chat-keyword .keyword-list .disabled {
  pointer-events: none;
}

.chat-keyword .keyword-list .keyword-link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 7px 16px;
  color: var(--ds-color-text-strong);
  font-size: var(--ds-font-size-lg);
  font-weight: var(--ds-font-weight-medium);
  border: 1px solid var(--ds-color-border-subtler);
  border-radius: var(--ds-border-radius-max) !important;
  white-space: nowrap;
}
.chat-keyword .keyword-list .keyword-link:not(:disabled)::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--ds-border-radius-max) !important;
}

.chat-keyword .keyword-list .keyword-link [class*='tag'] {
  padding: 0 8px;
  color: var(--ds-color-static-white);
  font-size: 0.8125rem;
  line-height: 1.25rem;
  background-color: var(--ds-color-primary);
  border-radius: var(--ds-border-radius-max);
}
.chat-keyword .keyword-list .keyword-refresh {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: var(--ds-color-fill-subtler);
}

.chat-history {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 32px 0 80px;
  color: var(--ds-color-text-default);
}
.chat-history-item {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.chat-message .msg-profile {
  display: flex;
  align-items: center;
  gap: 8px;
}
.chat-message .msg-profile .user-avatar {
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px var(--ds-color-border-subtler);
}
.chat-message .msg-profile .name {
  color: var(--ds-color-text-strong);
  font-size: var(--ds-font-size-lg);
}
.chat-message .chat-attached-list,
.chat-message .msg-content,
.chat-message .msg-indicator {
  margin-top: 4px;
  padding-left: var(--ds-chat-history-spacing);
  font-size: var(--ds-font-size-lg);
  line-height: var(--ds-line-height-reading);
}

.chat-message .msg-content .code-block {
  width: 90%;
}
.chat-message .msg-content .code-block .code {
  background-color: var(--ds-code-color-fill);
  color: var(--ds-color-text-inverse);
  border-radius: 0 0 var(--ds-border-radius-base) var(--ds-border-radius-base);
  overflow-x: auto;
  /* white-space: pre-wrap; */
  padding: 12px;
}
.chat-message .chat-starter {
  margin: 0;
}

/* 스크롤바 스타일링 */
.chat-message .msg-content ::-webkit-scrollbar {
  height: 12px;
}

.chat-message .msg-content ::-webkit-scrollbar-track {
  background: #2e2e2e; /* 스크롤바 트랙 배경색 */
}

.chat-message .msg-content ::-webkit-scrollbar-thumb {
  background: #5e5e5e; /* 스크롤바 색상 */
  border-radius: 3px;
}

.chat-message .msg-content ::-webkit-scrollbar-thumb:hover {
  background: #4e4e4e; /* 스크롤바 호버 색상 */
}
.chat-message .msg-content .code-block .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  color: var(--ds-color-text-inverse);
  background-color: var(--ds-color-normal-fill-default);
  border-radius: var(--ds-border-radius-base) var(--ds-border-radius-base) 0 0;
}

.chat-message .msg-content .button {
  color: var(--ds-color-text-inverse);
  box-shadow: none;
  background-color: var(--ds-color-normal-fill-default);
}

.chat-message .msg-content > *:first-child {
  margin-top: 0;
}
.chat-message .msg-content > *:last-child {
  margin-bottom: 0;
}

/* 메시지 내부 테이블 설정을 위해 */

.chat-message .msg-content .msg-table {
  width: 90%;

  overflow-x: auto;
  padding: 1px;
}

.chat-message .msg-content table {
  width: fit-content;

  border-collapse: collapse;
  border-radius: 8px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #e7e7e9;
  overflow: hidden;
  font-size: 15px;
}

.chat-message .msg-content tbody th,
.chat-message .msg-content tbody td {
  padding: 12px 20px;
  border: 1px solid #e7e7e9;
  background-color: #fff;
  text-align: left;
}

.chat-message .msg-content thead th {
  padding: 12px 20px;
  border: 1px solid #e7e7e9;
  background-color: #fff;
  text-align: left;
}

.chat-message .msg-content thead th {
  font-weight: bold;
  background-color: #767676;
  color: #fff;
  font-size: 14px;
}

.chat-message .msg-content p {
  margin: 0;
}

/* Error 창 */
.chat-message .msg-content .error-message {
  width: 18px;
  color: var(--ds-toast-color-icon);
  --ds-toast-fill: #fffafa;
  --ds-toast-border: #fed5d5;
  --ds-toast-color-text: var(--ds-color-text-strong);
  --ds-toast-color-icon: var(--ds-color-negative);
  width: 100%;
  margin: 4px 0;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: var(--ds-toast-color-text);
  font-size: var(--ds-font-size-lg);
  border-radius: var(--ds-border-radius-lg);
  border: 1px solid var(--ds-toast-border);
  background-color: var(--ds-toast-fill);
}

/* 주석이였음 */
.chat-message .msg-title {
  padding: 0.5rem 0;
  display: block;
  color: var(--ds-color-text-strong);
  font-size: var(--ds-font-size-md);
  font-weight: var(--ds-font-weight-semibold);
}
.chat-message .msg-subContent {
  margin-top: 10px;
  padding-left: var(--ds-chat-history-spacing);
}

/* chat reference */
.chat-message .msg-reference {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.chat-message .msg-reference .item {
  flex: calc(33.3% - 8px) 0 0;
  border: 1px solid var(--ds-color-border-subtler);
  border-radius: var(--ds-border-radius-xl);
}
.chat-message .msg-reference .ds-link {
  padding: 12px 16px;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-radius: inherit;
}
.chat-message .msg-reference .tit,
.chat-message .msg-reference .desc {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat-message .msg-content .msg-caution,
.chat-message .msg-reference .tit {
  width: 100%;
  /* margin-bottom: 8px; */
  color: var(--ds-color-text-strong);
  font-size: var(--ds-font-size-sm);
  font-weight: var(--ds-font-weight-medium);
}
.chat-message .msg-reference .tit > .desc {
  margin-bottom: 8px;
}
.chat-message .msg-content .msg-caution,
.chat-message .msg-reference .tit > i {
  width: 20px;
  margin-right: 8px;
  display: inline-block;
  color: var(--ds-color-primary);
  line-height: 20px;
  text-align: center;
  border-radius: var(--ds-border-radius-max);
  background-color: var(--ds-color-fill-subtler);
}
.chat-message .msg-reference .desc {
  flex: 1;
}
.chat-message .msg-reference .time {
  color: var(--ds-color-text-subtle);
}
.chat-message .msg-content .msg-caution {
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: text-top;
  width: fit-content;
  min-width: 20px;
}
/* chat indicator */
.chat-message .msg-indicator {
  display: flex;
  align-items: center;
  gap: 4px;
}
.chat-message .msg-indicator > p {
  margin: 0 10px 0 0;
}
.chat-message .msg-indicator .msg-icon {
  line-height: 1;
}
.chat-message .msg-indicator .msg-icon [class^='icon'] {
  stroke: var(--ds-color-primary);
  stroke-width: 1.5;
}
.chat-message .msg-indicator .msg-icon .icon-loading {
  transform-origin: center;
  stroke-dasharray: 40;
  stroke-dashoffset: 40;
  stroke-linecap: round;
  animation: typingLoading 2s ease-in-out infinite;
}
@keyframes typingLoading {
  0% {
    stroke-dashoffset: 40;
    transform: rotate(0);
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -40;
    transform: rotate(360deg);
  }
}
.chat-message .msg-indicator .ds-icon {
  width: 1.25em;
  color: var(--ds-color-primary);
}

.chat-message .msg-controls {
  margin-top: 10px;
  padding-left: var(--ds-chat-history-spacing);
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.chat-message .msg-controls [aria-pressed='true'] {
  color: var(--ds-color-primary);
}
.chat-message .msg-navigation .page {
  color: var(--ds-color-text-subtle);
  font-weight: var(--ds-font-weight-semibold);
  vertical-align: middle;
}

.chat-message-after {
  margin-left: var(--ds-chat-history-spacing);
  padding-top: 10px;
  border-top: 1px solid var(--ds-color-border-subtler);
}

.chat-history .keyword-list {
  /* margin-top: 10px; */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.chat-history .keyword-link {
  padding: 0.5rem 1rem;
  display: block;
  font-size: var(--ds-font-size-md);
  border: 1px solid var(--ds-color-border-subtler);
  border-radius: var(--ds-border-radius-max);
}
.chat-history .keyword-link img {
  margin: 0 4px 0 -8px;
}
.chat-history .msg-title {
  padding: 0.5rem 0;
  display: block;
  color: var(--ds-color-text-strong);
  font-size: var(--ds-font-size-md);
  font-weight: var(--ds-font-weight-semibold);
}
.chat-history .msg-title img {
  margin-top: -2px;
  vertical-align: middle;
}

.chat-bottom {
  position: sticky;
  bottom: 0;
  margin: auto -32px 0;
  padding: 0 32px 26px;
  background-color: var(--ds-color-surface-default);
}
.chat-bottom .go-end {
  position: relative;
  height: 80px;
  /* margin-top: -80px; */
  margin: -80px -32px auto;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}
.chat-bottom .go-end [data-state='hidden'] {
  animation: hideButton var(--ds-transition-base) forwards;
}
@keyframes hideButton {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    display: none;
  }
}
.chat-bottom .go-end .ds-control {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--ds-color-text-inactive);
  border: 1px solid var(--ds-color-border-inactive);
  border-radius: 100%;
  background-color: var(--ds-color-surface-paper);
}
.chat-bottom .go-end .ds-control::after {
  content: '';
  width: 1.75rem;
  -webkit-mask-image: var(--ds-icon-arrow);
}

.chat-input {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 15px 15px 23px;
  gap: 16px;
  border: 1px solid var(--ds-color-border-subtler);
  border-radius: var(--ds-border-radius-xxxl);
}
.chat-input:has(.input-text:not(:disabled):hover) {
  border-color: var(--ds-color-border-subtle);
}
.chat-input:has(.input-text:focus),
.chat-input:hover:has(.input-text:focus) {
  border-color: var(--ds-color-primary);
}
.chat-input .input-text {
  width: 100%;
  margin: 0;
  padding: 0;
  color: var(--ds-color-text-strong);
  font-size: var(--ds-font-size-lg);
  border: 0;
  outline: 0;
  background: none;
}
.chat-input .input-option {
  height: 2rem;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 12rem;
}

.chat-input .input-option.plugin-input-option {
  margin-right: 1rem;
}

.chat-input .input-option .option-item {
  position: relative;
}

.chat-input .chat-command {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chat-input .chat-command div:not(.enter-area) .ds-control:not(:disabled):hover {
  content: '';
  background-color: var(--ds-color-action-hover);
  border-radius: var(--ds-border-radius-base);
}
/* .chat-input .chat-command .ds-control:not(:disabled):focus {
  content: '';
  background-color: var(--ds-color-action-focus);
} */
.chat-input .chat-command div:not(.enter-area) .ds-control:not(:disabled):active {
  content: '';
  background-color: var(--ds-color-action-active);
  border-radius: var(--ds-border-radius-base);
}
.chat-input .enter-area {
  position: absolute;
  right: 15px;
  bottom: 15px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.chat-input .enter-area .chat-send,
.chat-input .enter-area .chat-stop {
  width: 2rem;
  height: 2rem;
  padding: 4px;
  color: var(--ds-color-text-inverse);
  border-radius: 100%;
  background-color: var(--ds-color-primary);
}
.chat-input .enter-area .chat-send .ds-icon {
  width: 100%;
}
.chat-input .enter-area .chat-send:disabled {
  background-color: var(--ds-color-fill-default);
}

.chat-attached-list ul {
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.chat-attached-list {
  display: none;
}
.chat-attached-list:has(ul .list-item) {
  display: block;
}
.chat-attached-list .list-item {
  position: relative;
  max-width: 100%;
  height: 4rem;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  list-style: none;
  border-radius: var(--ds-border-radius-lg);
  background-color: var(--ds-color-fill-subtler);
}
.chat-attached-list .file-preview {
  width: 3.75rem;
  aspect-ratio: 1;
  margin: -10px;
  border-radius: inherit;
  overflow: hidden;
}
.chat-attached-list .file-preview .state {
  padding: 0;
  margin: 0;
}
.chat-attached-list .file-preview .state::before {
  width: 2rem;
  height: 2rem;
  top: 20px;
  left: 14px;
}
.chat-attached-list .file-preview > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chat-attached-list .file-preview ~ .ds-control {
  position: absolute;
  top: 2px;
  right: 2px;
}
.chat-attached-list .file-icon {
  width: 1.875rem;
  aspect-ratio: 1;
  flex-shrink: 0;
}
.chat-attached-list .file {
  min-width: 1rem; /* flex 영역 인식 */
}
.chat-attached-list .file .name {
  display: block;
  font-size: var(--ds-font-size-sm);
  /* 말줄임 처리 */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat-attached-list .file .size {
  color: var(--ds-color-text-subtle);
  font-size: 0.69rem; /* 11px */
}
.chat-attached-list .state {
  position: relative;
  margin-left: 0.5rem;
  padding-left: 1rem;
  font-size: var(--ds-font-size-sm);
}
.chat-attached-list .state::before {
  position: absolute;
  width: 1em;
  height: 1em;
  top: 50%;
  left: 0;
  margin-top: -0.5em;
  display: block;
  content: '';
  mask-position: 50%;
  background-color: var(--ds-color-primary);
}
.chat-attached-list .is-loading::before {
  mask-image: var(--ds-icon-loading);
  mask-size: contain;
  animation: fileLoading 2s ease-in-out infinite;
}
.chat-attached-list .is-complete::before {
  mask-image: var(--ds-icon-check);
  mask-size: 110%;
}
@keyframes fileLoading {
  100% {
    transform: rotate(360deg);
  }
}
.chat-room .plugin-profile {
  max-width: 498px;
  margin: 0 auto;
}
.chat-room .plugin-profile .text-ellipsis {
  display: -webkit-box;
  max-height: calc(var(--ds-line-height-base) * 2rem);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.chat-starter {
  width: 100%;
  max-width: 498px;
  margin: 0 auto;
  padding-left: var(--ds-chat-history-spacing);
  padding-top: 10px;
}
.chat-starter-item {
  --chat-starter-text-color: inherit;
  --chat-starter-border-color: var(--ds-color-border-subtler);
  --chat-starter-icon-color: var(--ds-color-fill-strong);
  width: 100%;
  height: 44px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  color: var(--chat-starter-text-color);
  font-size: var(--ds-font-size-md-02);
  text-align: left;
  border: 1px solid var(--chat-starter-border-color);
  border-radius: var(--ds-border-radius-lg);
}
.chat-starter-item.disabled {
  pointer-events: none;
}

.chat-starter-item:not(:first-child) {
  margin-top: 0.75rem;
}
.chat-starter-item:hover,
.chat-starter-item:focus-visible {
  --chat-starter-text-color: var(--dsx-color-primary-text-default);
  --chat-starter-border-color: var(--dsx-color-primary-border-neutral);
  --chat-starter-icon-color: var(--dsx-color-primary-icon-neutral);
}
.chat-starter-item .name {
  flex: 1;
  font-weight: var(--ds-font-weight-medium);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-starter-item .dsx-Icon {
  margin-left: auto;
  color: var(--chat-starter-icon-color);
}
.chat-starter-item .ds-icon {
  margin-left: auto;
  color: var(--chat-starter-icon-color);
}

.chat-room .ds-popover {
  outline: none;
}
.chat-view .model-list {
  max-height: 255px;
  margin: 0 -11px;
  overflow-y: auto;
}
.chat-view .model-list-item {
  min-width: 0;
}
.chat-view .model-list-item .model-item {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  cursor: pointer;
}
.chat-view .model-list-item.hover,
.chat-view .model-list-item:not(:disabled):hover {
  content: '';
  background-color: var(--ds-color-action-hover);
  border-radius: var(--ds-border-radius-lg);
}
.chat-button-edit {
  color: var(--ds-color-text-subtle);
  font-size: var(--ds-font-size-md);
}
.chat-view .model-list-item:not(:disabled):focus-visible {
  content: '';
  background-color: var(--ds-color-action-focus);
  border-radius: var(--ds-border-radius-lg);
}
.chat-view .model-list-item:not(:disabled):active {
  content: '';
  background-color: var(--ds-color-action-active);
  border-radius: var(--ds-border-radius-lg);
}

.chat-view .model-list-item .model-item[aria-selected='true'] {
  padding-right: 44px;
  background: var(--ds-icon-check) no-repeat top 50% right 12px;
  background-size: 24px;
}
.chat-view .model-list-item .model-item[data-disabled='true'] :not(.ds-tag) {
  color: var(--ds-color-text-disabled);
}
.chat-view .model-list-item .model-item[data-disabled='true'] .symbol {
  opacity: 0.4;
}

.chat-view .model-list-item .symbol {
  width: 2rem;
  flex-shrink: 0;
  aspect-ratio: 1;
  border-radius: 100%;
  overflow: hidden;
  background: var(--ds-icon-profile-fill) 50% 50% no-repeat;
  background-size: 100%;
}
.chat-view .model-list-item .symbol > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chat-view .model-list-item .title {
  min-width: 5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 3px 8px;
}
.chat-view .model-list-item:has(.suffix) .name {
  flex: none;
}
.chat-view .model-list-item .name {
  flex: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat-view .model-list-item .ds-tag,
.chat-view .model-list-item .dsx-Badge {
  flex-shrink: 0;
}
.chat-view .model-list-item .desc {
  width: 100%;
  display: block;
  color: var(--ds-color-text-subtle);
  font-size: var(--ds-font-size-sm);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

.chat-view .model-list-item .suffix:not(.ds-tag) {
  font-size: var(--ds-font-size-sm);
}

.chat-caution {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.chat-plugin,
.chat-attach,
.chat-template
.chat-websearch {
  position: static !important;
}
.chat-plugin .ds-control:where(:hover),
.chat-attach .ds-control:where(:hover),
.option-item .ds-control:where(:hover),
.chat-websearch .ds-control:where(:hover),
.chat-code .ds-control:where(:hover){
  border-radius: var(--ds-border-radius-m);
  background-color: var(--ds-color-action-hover);
}

.set-plugin {
  line-height: var(--ds-line-height-fit);
}
.set-plugin .model-name {
  padding: 0 8px 0 4px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: var(--ds-color-primary);
  font-weight: var(--ds-font-weight-medium);
  border-radius: var(--ds-border-radius-base);
  background-color: var(--ds-color-fill-subtler);
}
.set-plugin .model-name .ds-icon {
  width: 1.25rem;
}
.set-plugin .model-name .ds-control-close::before {
  width: 1rem;
  color: var(--ds-color-text-default);
}

.chat-plugin .ds-popover {
  bottom: calc(100% + 8px);
  left: 0;
}

.chat-attach .ds-popover {
  width: 100% !important;
  bottom: calc(100% + 8px);
  left: 0;
  border-radius: var(--ds-border-radius-xl);
}
.chat-attach .is-show {
  visibility: visible;
}
.chat-attach .is-hidden {
  visibility: hidden;
}
.chat-template .ds-popover {
  width: 100% !important;
  bottom: calc(100% + 8px);
  left: 0;
  border-radius: var(--ds-border-radius-xxl);
}
.chat-template .template-wrap {
  max-height: 370px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  overflow-y: auto;
}

.chat-template .ing-skeleton {
  display: none;
}
.chat-template .template-item {
  display: block;
}
.chat-template .template-item-height {
  min-height: 150px;
}

.chat-template .template-item-skeleton {
  background: linear-gradient(120deg, #f4f4f5 30%, #fcfcfc 39.5%, #fcfcfc 40%, #f4f4f5 48%);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: skeletonLoad 1.3s infinite;

  height: 125px;
  border: 1px solid var(--ds-color-border-subtler);
  border-radius: var(--ds-border-radius-xl);
}

.chat-template .template-item .ds-tag {
  font-size: var(--ds-font-size-md);
}
.chat-template .template-item .ds-link {
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border: 1px solid var(--ds-color-border-subtler);
  border-radius: var(--ds-border-radius-xl);
  transition: background-color var(--ds-transition-base);
}
.chat-template .template-item .ds-link:hover,
.chat-template .template-item .ds-link:focus-visible {
  background-color: var(--ds-color-fill-subtler);
}
.chat-template .template-item .name {
  color: var(--ds-color-text-strong);
  font-size: var(--ds-font-size-h3);
  font-weight: var(--ds-font-weight-semibold);

  /* 1줄만 표시 */
  display: -webkit-box;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.chat-template .template-item .desc {
  margin: 0;
  display: -webkit-box;
  color: var(--ds-color-text-subtle);
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.chat-websearch .ds-control:not(.disabled):where(:focus-visible, :active, .is-active), .chat-websearch.on {
  /* --chat-websearch-icon-color: var(--ds-color-primary); */
  --chat-websearch-icon-color: var(--ds-color-primary);
  --chat-websearch-text-color: var(--ds-color-primary);
}
.chat-websearch .ds-control:where(:hover)>span, .chat-websearch.on .ds-control>span{
  padding-right: 3px;
}
.chat-websearch .ds-control {
  color: var(--chat-websearch-text-color);
}
.chat-websearch .ds-icon {
  color: var(--chat-websearch-icon-color);
}

.chat-select {
  position: relative;
}
.chat-select .ds-popover {
  bottom: calc(100% + 8px);
  right: 0;
}

.ds-popover[role='listbox'] {
  width: 22.5rem;
}

.chat-set {
  padding: var(--ds-chat-aside-spacer) 0;
  border-bottom: 1px solid var(--ds-color-border-subtle);
  /* background-color: var(--ds-chat-aside-background-color); */
  z-index: 1;
}

.chat-button {
  --chat-button-text-color: var(--ds-color-text-inverse);
  --chat-button-background-color: var(--ds-color-normal);

  width: 100%;
  height: 40px;
  color: var(--chat-button-text-color);
  font-weight: var(--ds-font-weight-semibold);
  border-radius: var(--ds-border-radius-lg);
  background-color: var(--chat-button-background-color);
  appearance: none;
  transition: background-color var(--ds-transition-base);
}
.chat-button:not(:disabled):where(:hover, :focus-visible, :active) {
  --chat-button-background-color: #333438;
}
.chat-button:disabled {
  --chat-button-text-color: var(--ds-color-text-disabled);
  --chat-button-background-color: var(--ds-color-fill-disabled);
}
/* 키워드 호버, 클릭시 색 변화 */
.keyword-item .chat-button {
  color: var(--ds-color-text-strong);
  --chat-button-background-color: var(--ds-color-white);
}
.keyword-item .chat-button:not(:disabled):where(:hover, :active) {
  color: var(--ds-color-text-strong);
  --chat-button-background-color: var(--ds-color-action-hover);
}
.keyword-item .chat-button:not(:disabled):where(:focus-visible) {
  color: var(--ds-color-text-strong);
  --chat-button-background-color: var(--ds-color-action-focus);
}
.keyword-item .chat-button .disabled {
  pointer-events: none;
}

.chat-services {
  padding-top: var(--ds-chat-aside-spacer);
}
.chat-services .ds-control {
  --chat-services-icon-color: var(--ds-color-normal);
  --chat-services-text-color: inherit;

  width: 100%;
  padding: 0.75rem 1.25rem;
  justify-content: flex-start;
  gap: 12px;
  color: var(--chat-services-text-color);
  font-size: var(--ds-font-size-md-02);
}
.chat-services .ds-control:not(.disabled):where(:hover, :focus-visible, :active, .is-active) {
  --chat-services-icon-color: var(--ds-color-primary);
  --chat-services-text-color: var(--ds-color-primary);
}
.chat-services .ds-icon {
  width: 1.5rem;
  color: var(--chat-services-icon-color);
}

.chat-nav {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  margin: 0 calc(var(--ds-chat-aside-spacer) * -1);
  padding: var(--ds-chat-aside-spacer);
}
.chat-nav .nav-section {
  margin-top: var(--ds-chat-aside-spacer);
}
.chat-nav .nav-text {
  text-align: center;
  display: block;
  padding: 10px;
}

.chat-date {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  padding: 0 24px;
  color: var(--ds-color-text-subtle);
  font-size: var(--ds-font-size-sm);
  cursor: pointer;
}
.chat-date .arrow {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(/src/assets/etc-icons/ico_arrow_down.svg);
  background-size: 20px;
  transition:
    opacity 0.5s ease,
    transform 0.5s ease,
    background-image 0.5s ease;
  opacity: 0.8;
}

.chat-date .arrow.isClose {
  transform: scaleY(-1);
}

.chat-list-item {
  position: relative;
  border-radius: var(--ds-border-radius-lg);
}
.chat-list-item:has(.chat-link:hover, .chat-option:hover, .chat-link.is-active) {
  background-color: var(--ds-color-action-hover);
}
.chat-list-item:has(.chat-link:focus-visible, .chat-option > :focus-visible) {
  background-color: var(--ds-color-action-focus);
}
.chat-list-item:has(.chat-link:hover, .chat-option:hover, .chat-link:focus-visible, .chat-option > :focus-visible)
  .chat-link {
  padding-right: 4.5rem;
}
.chat-list-item:has(.chat-link:hover, .chat-option:hover, .chat-link:focus-visible, .chat-option > :focus-visible)
  .chat-link::after {
  display: none;
}
.chat-list-item:has(.chat-link:hover, .chat-option:hover, .chat-link:focus-visible, .chat-option > :focus-visible)
  .chat-option {
  z-index: 1;
}
.chat-list-item:has(.chat-edit-name) .chat-link .name {
  opacity: 0;
}
.chat-list-item:has(.chat-edit-name) .chat-option {
  z-index: -2;
}
.chat-list .chat-link {
  display: block;
  padding: 12px 20px;
  padding-right: 3.5rem;
  border-radius: var(--ds-border-radius-lg);
}
.chat-list .chat-link:active {
  background-color: var(--ds-color-action-active);
}
.chat-list .chat-link .name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-list .chat-link .desc {
  display: block;
  color: var(--ds-color-text-subtle);
  font-size: var(--ds-font-size-sm);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-more {
  position: absolute;
  top: 50%;
  right: 1.25rem;
  color: var(--ds-color-normal);
  transform: translateY(-50%);
}
.chat-option {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid var(--ds-color-border-subtler);
  border-radius: var(--ds-border-radius-xl);
  background-color: var(--ds-color-static-white);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
.chat-option .ds-control {
  padding: 0.25rem 0.75rem;
  justify-content: flex-start;
}
.chat-list .chat-edit-name {
  position: absolute;
  inset: 0;
  padding: 12px 3.25rem 12px 20px;
}
.chat-list .chat-edit-name .edit-input {
  width: 100%;
  margin: 0;
  padding: 0;
  font-weight: var(--ds-font-weight-semibold);
  border: 0;
  background: none;
} 
.chat-warning {
  margin: 1em 0 0 0;
  color: var(--ds-color-text-subtle);
  text-align: center;
}
.chat-warning .ds-icon {
  width: 16px;
  margin-right: 6px;
  vertical-align: middle;
}

.chat-info {
  margin: 1em 0 0 0;
  color: var(--ds-color-text-subtle);
}
.chat-info .ds-icon {
  width: 16px;
  margin-right: 6px;
  vertical-align: middle;
}

/* File-uploader */
.file-uploader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 23px;
  border: 1px dashed var(--ds-color-caution);
  border-radius: inherit;
  background-color: rgba(255, 146, 0, 0.15);
  visibility: hidden;
  overflow: hidden;
}
.file-uploader > * {
  /* drag event 시 자식요소 마우스 이벤트 트리거 막기 */
  /* pointer-events : none; */
}
.file-uploader input[type='file'] {
  display: none;
}
.file-uploader .uploader-inner {
  height: 100%;
}
.uploader-inner {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  font-size: var(--ds-font-size-md-02);
  color: var(--ds-color-text-strong);
  text-align: center;
  height: 100%;
}
.uploader-inner img {
  width: 2.5rem;
  height: 2.5rem;
}
.uploader-inner .desc {
  margin: 0;
  font-size: var(--ds-font-size-md);
  color: var(--ds-color-text-subtle);
}
.uploader-inner .btn-wrap {
  margin-top: 12px;
  display: flex;
  gap: 8px;
}
.uploader-inner .btn-wrap .ds-button {
  font-size: var(--ds-font-size-base);
  min-width: 122px;
  height: 2rem;
}

.is-none {
  display: none;
}

/* ==== Mobile Device ==== */
@media (pointer: coarse) and (max-width: 768px) {
  :root {
    --ds-font-size-display1: 1.625rem;
    --ds-font-size-lg: 0.875rem;
    --ds-transition-popover: 0.3s ease-out;
    --mobile-base-spacing: 1rem;
  }
  body {
    min-width: 100% !important;
  }
  .chat-view {
    --ds-chat-aside-background-color: var(--ds-color-static-white);
    --ds-chat-history-spacing: calc(1.25rem + 8px);
    --ds-chat-mobile-header-height: 2.5rem;
    grid-template-columns: 1fr;
    grid-template-rows: var(--ds-chat-mobile-header-height) 1fr;
  }
  .chat-view:has(.chat-aside:not(.is-close))::after {
    position: fixed;
    width: 100vw;
    height: calc(100vh - var(--ds-chat-mobile-header-height));
    left: 0;
    bottom: 0;
    display: block;
    content: '';
    background-color: var(--ds-color-dimmer);
  }
  .chat-header-mobile {
    padding: 0 var(--mobile-base-spacing);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--ds-color-static-white);
    background-color: var(--ds-color-primary);
  }
  .chat-header-mobile .ds-control {
    --ds-control-size: 1.5rem;
  }
  .chat-header-mobile .ds-control-dropdown:where(:hover, :focus) {
    color: inherit;
  }

  .chat-aside {
    position: absolute;
    height: calc(100% - 2.5rem);
    bottom: 0;
    z-index: 5;
  }
  .chat-aside .chat-aside-collapse {
    top: 1.25rem;
    transform: none;
  }
  .chat-aside-collapse.is-close {
    display: none;
  }
  .chat-aside-collapse .collapse-control .move-arrow {
    height: 1.5rem;
    mask-image: var(--ds-icon-close);
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: var(--ds-color-static-white);
    opacity: 1;
  }
  .chat-aside-collapse .collapse-control .move-arrow::before,
  .chat-aside-collapse .collapse-control .move-arrow::after {
    display: none;
  }

  .chat-room {
    width: calc(100% - 2rem);
    min-width: auto;
    padding: 0 var(--mobile-base-spacing);
  }
  .chat-room .model-list {
    max-height: none;
    margin: 0;
    overflow-y: visible;
  }
  .model-list .model-list-item .model-item {
    border-radius: var(--ds-border-radius-lg);
  }
  .model-list .model-list-item .model-item[aria-selected='true'] {
    background-color: var(--ds-color-action-hover);
  }
  .chat-visual .visual {
    height: 100px;
  }
  .chat-keyword {
    max-width: 100%;
  }
  .chat-keyword .keyword-list .keyword-link {
    white-space: inherit;
    text-align: center;
  }

  .chat-message .msg-reference {
    margin-right: calc(var(--mobile-base-spacing) * -1);
    padding: 2px var(--mobile-base-spacing) 2px 0;

    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .chat-message .msg-reference .item {
    flex-basis: 85%;
    max-width: 320px;
  }
  .chat-message .msg-reference .ds-link {
    font-size: var(--ds-font-size-md);
  }
  .chat-input {
    margin: 0 40px 0 34px;
    padding: 6px 10px;
    gap: 0.375rem;
    border-radius: var(--ds-border-radius-xl);
  }
  .chat-input .input-option {
    position: absolute;
    height: auto;
    left: calc((1.75rem + 6px) * -1);
    bottom: 40px;
    margin: 0;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4px;
    border: 1px solid var(--ds-color-border-subtler);
    border-radius: var(--ds-border-radius-xl);
    background-color: var(--ds-color-static-white);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  }
  .chat-input .input-option[data-state='hide'] {
    visibility: hidden;
  }
  .chat-input .input-option .ds-control:not([class*='control-']) {
    padding: 4px 12px;
  }
  .chat-input .enter-area {
    right: calc((2rem + 8px) * -1);
    bottom: 0;
  }
  .chat-input .chat-functions {
    --ds-control-size: 1.75rem;
    position: absolute;
    left: calc((1.75rem + 6px) * -1);
    bottom: 2px;
    border-radius: var(--ds-border-radius-max);
  }
  .set-plugin {
    margin-top: 2px;
  }
  .chat-plugin .ds-popover,
  .chat-template .ds-popover {
    left: 0;
    bottom: 0;
    padding: 12px;
  }
  .chat-template .template-wrap {
    max-height: none;
    margin-top: 0;
    grid-template-columns: 1fr;
    overflow-y: visible;
  }

  .chat-bottom {
    margin: auto -1rem 0;
    padding: 0 1rem 0.75rem;
  }
  .chat-warning {
    display: none;
  }

  .chat-plugin:has(.ds-popover[data-state='show'])::after,
  .ds-dropdown:has(.ds-popover[data-state='show'])::after {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: block;
    content: '';
    background-color: var(--ds-color-dimmer);
    visibility: visible;
    z-index: 1;
  }
  .ds-popover {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100% !important;
    max-width: 100%;
    max-height: 80vh;
    padding: 12px;
    border-radius: var(--ds-border-radius-xl) var(--ds-border-radius-xl) 0 0 !important;
    overflow-y: auto;
  }
  .ds-popover[data-state='show'] {
    animation: popoverUp var(--ds-transition-popover) forwards;
  }
  .ds-dropdown-header {
    position: sticky;
    top: -12px;
    margin: -12px -12px 0;
    padding: 1rem 1.5rem;
    background-color: inherit;
    z-index: 1;
  }
  .ds-modal-overlay {
    padding: 1.5rem;
  }
  .ds-modal-alert {
    max-width: 100%;
  }
  .ds-modal-toast {
    max-width: calc(100% - 2rem);
    left: 50%;
    bottom: 2rem;
  }
  .toast_message {
    font-size: var(--ds-font-size-base);
  }
  .ds-avatar {
    --ds-avatar-size: 1.25rem;
  }

  .dsx-Avatar {
    --dsx-avatar-size: 1.25rem;
  }
  .ds-popover .ds-text-info {
    position: sticky;
    bottom: -12px;
    margin: 0 -12px -12px;
    padding: 12px 0;
    background-color: var(--dsx-color-static-white);
  }
  .ds-text-info {
    color: var(--dsx-color-primary-text-neutral);
    text-align: center;
  }
  .ds-text-info::before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin: -2px 4px 0 0;
    vertical-align: middle;
    mask: var(--dsx-icon-info) 50% 50% no-repeat;
    mask-size: 100%;
    background-color: currentColor;
  }

  @keyframes popoverUp {
    from {
      opacity: 0;
      transform: translateY(1rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}
