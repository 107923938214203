@import '../config/index.scss';

.dsx-Empty {
  --dsx-empty-image-size: 100px;
  --dsx-empty-icon-size: calc(var(--dsx-empty-image-size) / 2);
  --dsx-empty-icon-color: none;
  --dsx-empty-icon-margin-bottom: var(--dsx-space-6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;
  color: var(--dsx-color-neutral-text-alternative);
  text-align: center;
  &-image {
    height: rem(50px);
    margin-bottom: var(--dsx-space-2);
  }
  &-icon {
    --dsx-icon-size: var(--dsx-empty-icon-size);
    --dsx-icon-color: var(--dsx-empty-icon-color);
    width: var(--dsx-empty-image-size);
    height: var(--dsx-empty-image-size);
    margin-bottom: var(--dsx-empty-icon-margin-bottom);
    border-radius: var(--dsx-radius-max);
    background-color: var(--dsx-color-neutral-fill-alternative);
  }
  &-data {
    --dsx-icon-size: var(--dsx-empty-icon-size);
    --dsx-icon-color: var(--dsx-empty-icon-color);
    margin-bottom: var(--dsx-empty-icon-margin-bottom);
  }
  .dsx-Heading {
    color: var(--dsx-color-neutral-text-alternative);
  }
  .dsx-Text {
    margin-top: var(--dsx-space-3);
  }
  &-controls {
    display: flex;
    gap: 8px;
    margin-top: var(--dsx-space-6);
  }

  // variant
  &--data {
    --dsx-empty-icon-size: var(--dsx-scale-4);
    --dsx-empty-icon-color: var(--dsx-color-normal-icon-neutral);
    --dsx-empty-icon-margin-bottom: var(--dsx-space-3);
  }
}
