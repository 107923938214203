.ds-modal-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  overflow: auto;
  z-index: $z-index-modal;
  visibility: hidden;
  &::before {
    content: '';
    position: fixed;
    inset: 0;
    background-color: var(--ds-color-dimmer);
  }
  &.is-show {
    visibility: visible;
  }
  &[data-state="show"] {
    .ds-dialog {
      animation: fadeInUp var(--ds-transition-popover) forwards;
      visibility: visible;
    }
  }
  &[data-state="hide"] {
    .ds-dialog {
      animation: fadeOutDown var(--ds-transition-popover) forwards;
      visibility: hidden;
    }
  }
}

// 콘텐츠 팝업
.ds-modal-dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  width: rem(600px);
  padding: 23px;
  border: 1px solid var(--ds-color-border-default);
  border-radius: var(--ds-border-radius-lg);
  background: var(--ds-color-surface-overlay);
  box-shadow: var(--ds-box-shadow-base);
  .dialog-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h2 {
     margin: 0; 
    }
    .ds-control-close {
      margin-left: auto;
      margin-right: -4px;
      padding: 6px;
    }
  }
  .dialog-content {
    max-height: 100%;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 24px;
    .ds-button {
      min-width: rem(84px);
    }
  }
}

// 시스템 팝업
.ds-modal-alert {
  position: relative;
  display: flex;
  flex-direction: column;
  width: rem(320px);
  padding: 23px;
  border: 1px solid var(--ds-color-border-default);
  border-radius: var(--ds-border-radius-lg);
  background: var(--ds-color-surface-overlay);
  box-shadow: var(--ds-box-shadow-base);
  .alert-content {
    min-height: 2em;
    padding: 12px 0;
    text-align: center;
    .alert-title {
      color: var(--ds-color-text-strong);
      font-size: var(--ds-font-size-h1);
      margin: 0 0 12px;
    }
    .alert-desc {
      min-height: 2em;
      margin: 0;
      font-size: var(--ds-font-size-lg);
    }
  }
  .alert-footer {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    .ds-button {
      flex: 1 1 0;
    }
  }
}