@import '../config/index.scss';

/* Pagination */
.dsx-Pagination {
  --dsx-pagination-button-size: #{rem(32px)};
  --dsx-pagination-button-border-color: transparent;
  --dsx-pagination-button-border-radius: var(--dsx-radius-max);
  --dsx-pagination-button-background-color: transparent;
  --dsx-pagination-button-current-text: var(--dsx-color-primary-text-default);
  --dsx-pagination-button-current-color: transparent;
  position: relative;
  @include flex(flex, center, center);
  gap: var(--dsx-space-5);
  margin-top: vaR(--dsx-space-5);
  padding: 0 rem(180px);
  .dsx-PagingTotal {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    gap: var(--dsx-space-5);
  }
  .dsx-Paging {
    @include flex(flex, center, center);
    gap: var(--dsx-space-3);
    &-text {
      @include flex(flex, center, center);
      min-width: var(--dsx-pagination-button-size);
      height: var(--dsx-pagination-button-size);
    }
    &-button {
      --dsx-icon-size: #{rem(20px)};
      position: relative;
      @include flex(flex, center, center);
      min-width: var(--dsx-pagination-button-size);
      height: var(--dsx-pagination-button-size);
      padding: 0 var(--dsx-space-2);
      border-radius: var(--dsx-pagination-button-border-radius);
      background-color: var(--dsx-pagination-button-background-color);
      box-shadow: inset 0 0 0 1px var(--dsx-pagination-button-border-color);
      &--arrow {
        background-color: #f7f7f8;
      }
      &[aria-current='page'] {
        font-weight: var(--dsx-font-weight-semibold);
        color: var(--dsx-pagination-button-current-text);
        border-color: var(--dsx-pagination-button-current-color);
        background: var(--dsx-pagination-button-current-color);
      }
      @include interaction();
      &:disabled {
        color: var(--dsx-color-interaction-text-disabled);
      }
    }
    &-items {
      @include flex(flex, center, center);
      gap: var(--dsx-space-2);
    }
  }
  .dsx-PagingJump {
    position: absolute;
    top: 0;
    right: 0;
    .dsx-Input {
      max-width: rem(56px);
      padding: 0;
      text-align: center;
    }
    .dsx-Button {
      margin-left: 8px;
    }
  }

  // variant
  &--outline {
    --dsx-pagination-button-border-color: var(--dsx-color-neutral-border-alternative);
    --dsx-pagination-button-current-text: var(--dsx-color-inverse-text);
    --dsx-pagination-button-current-color: var(--dsx-color-primary-fill-default);
  }
}
