/* control */

// button 컴포넌트 외 컴포넌트 내부 또는 커스텀 버튼이 필요한 경우 사용
.ds-control {
  position: relative;
  @include flex(inline-flex, center, center);
  gap: 4px;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: none;
  appearance: none;
  vertical-align: middle;
  &:before, &:after {
    width: rem(20px);
    aspect-ratio: 1;
    -webkit-mask-size: 100%;
    background-color: currentColor;
  }
  &:disabled, &[aria-disabled="true"] {
    color: var(--ds-color-text-disabled);
  }
  .ds-icon {
    width: rem(20px);
  }
  &.control-lg {
    .ds-icon {
      width: rem(24px);
    }
  }
}

// 공통 컨트롤 버튼
.ds-control-prev {
  &::before {
    content: '';
    -webkit-mask-image: var(--ds-icon-arrow);
    transform: rotate(90deg);
  }
}
.ds-control-next {
  &::after {
    content: '';
    -webkit-mask-image: var(--ds-icon-arrow);
    transform: rotate(-90deg);
  }
}
.ds-control-close {
  &::before {
    content: '';
    -webkit-mask-image: var(--ds-icon-close);
  }
}
.ds-control-pin {
  &::before {
    content: '';
    display: inline-block;
    width: rem(16px);
    height: rem(16px);
    -webkit-mask: var(--ds-icon-bookmark) 50% 50% no-repeat;
    -webkit-mask-size: 100%;
    background-color: currentColor;
  }
}
.ds-control-dropdown {
  &::after {
    content: '';
    display: inline-block;
    -webkit-mask: var(--ds-icon-arrow) 50% 50% no-repeat;
    -webkit-mask-size: 100%;
    background-color: currentColor;
  }
  &:hover, &:focus {
    color: var(--ds-color-primary);
  }
}
.ds-control-more {
  &::after {
    content: '';
    display: inline-block;
    -webkit-mask: var(--ds-icon-more) 50% 50% no-repeat;
    -webkit-mask-size: 100%;
    background-color: currentColor;
  }
}
.ds-control-remove {
  width: rem(20px);
  border-radius: 100%;
  background-color: $neutral-95;
  &::before {
    content: '';
    -webkit-mask: var(--ds-icon-remove) 50% 50% no-repeat;
    -webkit-mask-size: 100%;
    background-color: currentColor;
  }
}