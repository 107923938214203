@import '../config/index.scss';

/* dropdown menu */
.dsx-Combobox {
  --dsx-combobox-height: var(--dsx-scale-5);
  --dsx-combobox-padding: 0 var(--dsx-space-4);
  --dsx-combobox-font-color: var(--dsx-color-neutral-text-default);
  --dsx-combobox-font-size: var(--dsx-font-size-base);
  --dsx-combobox-border-width: var(--dsx-stroke-base);
  --dsx-combobox-border-color: var(--dsx-color-neutral-border-alternative);
  --dsx-combobox-border-radius: var(--dsx-radius-base);
  --dsx-combobox-background-color: var(--dsx-color-neutral-surface-default);
  --dsx-icon-color: var(--dsx-color-neutral-text-alternative);
  display: flex;
  align-items: center;
  gap: var(--dsx-space-3);
  width: 100%;
  min-width: rem(160px);
  height: var(--dsx-combobox-height);
  padding: var(--dsx-combobox-padding);
  color: var(--dsx-combobox-font-color);
  font-size: var(--dsx-combobox-font-size);
  border-radius: var(--dsx-combobox-border-radius);
  box-shadow: inset 0 0 0 var(--dsx-combobox-border-width) var(--dsx-combobox-border-color);
  background-color: var(--dsx-combobox-background-color);
  transition: background-color var(--dsx-transition-base);
  &:disabled {
    --dsx-combobox-font-color: var(--dsx-color-interaction-text-disabled);
    --dsx-combobox-border-color: var(--dsx-color-interaction-border-disabled) !important;
    --dsx-combobox-background-color: var(--dsx-color-interaction-fill-disabled);
  }
  &-arrow {
    margin-left: auto;
    margin-right: calc(var(--dsx-space-2) * -1);
  }
  &[aria-expanded='true'] {
    --dsx-combobox-border-color: var(--dsx-color-primary-border-default);
  }
  // variant
  &--filled {
    --dsx-combobox-background-color: var(--dsx-color-neutral-fill-alternative);
  }
  // size
  &--small {
    --dsx-combobox-height: var(--dsx-scale-4);
    --dsx-combobox-padding: 0 var(--dsx-space-3);
    --dsx-combobox-font-size: var(--dsx-font-size-small);
  }
  &--large {
    --dsx-combobox-height: var(--dsx-scale-6);
    --dsx-combobox-padding: 0 var(--dsx-space-5);
  }
  &--large &-arrow {
    margin-right: calc(var(--dsx-space-2) * -1.5);
  }
}
.dsx-DropdownMenu {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  &-listbox {
    --dsx-listbox-transform-origin: top center;
    display: flex;
    flex-direction: column;
    gap: var(--dsx-space-1);
    width: fit-content;
    min-width: 100%;
    max-height: rem(210px);
    margin: 4px 0;
    overflow-y: auto;
    padding: calc(var(--dsx-space-2) - var(--dsx-stroke-base));
    border-radius: var(--dsx-radius-xlarge);
    border: var(--dsx-stroke-base) solid var(--dsx-color-neutral-border-alternative);
    background: var(--dsx-color-neutral-surface-default);
    box-shadow: var(--dsx-shadow-3);
    transform-origin: var(--dsx-listbox-transform-origin);
    @include scrollbar();
    &[data-state='show'] {
      animation:
        dsx-slide-from-bottom var(--dsx-transition-popover) forwards,
        dsx-fade-in var(--dsx-transition-popover) forwards;
    }
    &[data-state='hide'] {
      animation:
        dsx-slide-to-bottom var(--dsx-transition-popover) forwards,
        dsx-fade-out var(--dsx-transition-popover) forwards;
    }
  }

  // listbox option
  &-option {
    --dsx-icon-size: #{rem(24px)};
    flex: 0 0 auto;
    position: relative;
    @include flex(flex, flex-start, center);
    gap: var(--dsx-space-2);
    width: 100%;
    height: var(--dsx-scale-5);
    padding: 0 var(--dsx-space-3);
    color: var(--dsx-color-neutral-text-default);
    text-align: left;
    line-height: 1.3;
    white-space: nowrap;
    border-radius: var(--dsx-radius-small);
    &[tabindex='0'] {
      cursor: pointer;
    }
    @include interaction();
    &[aria-selected='true'] {
      &::after {
        content: '';
        opacity: 0.0375;
        background: var(--dsx-color-primary-fill-default);
        visibility: visible;
      }
    }
    &.is-current {
      background-color: #000;
    }
  }
}
