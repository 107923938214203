@import '../config/index.scss';

/* Input */
.dsx-Input {
  --dsx-input-width: #{rem(280px)};
  --dsx-input-height: #{rem(32px)};
  --dsx-input-padding: 0 8px;
  --dsx-input-border-color: var(--dsx-color-neutral-border-alternative);
  --dsx-input-border-radius: var(--dsx-radius-base);
  --dsx-input-background-color: var(--dsx-color-neutral-surface-default);
  --dsx-input-focus-border-color: var(--dsx-color-primary-border-default);
  --dsx-icon-size: max(#{rem(16px)}, calc(var(--dsx-input-height) / 2));
  position: relative;
  @include flex(inline-flex, flex-start, center);
  gap: 10px 4px;
  width: var(--dsx-input-width);
  max-width: 100%;
  height: var(--dsx-input-height);
  padding: var(--dsx-input-padding);
  color: var(--dsx-color-neutral-text-default);
  vertical-align: middle;
  border: 1px solid var(--dsx-input-border-color);
  border-radius: var(--dsx-input-border-radius);
  background-color: var(--dsx-input-background-color);
  transition: background-color var(--dsx-transition-base);
  cursor: text;
  // @include interaction();

  // TEXT FIELD
  &-field {
    position: relative;
    appearance: none;
    width: 100%;
    height: 100%;
    padding: 0 4px;
    font-size: var(--dsx-input-font-size);
    text-align: inherit;
    background: none;
    outline: 0;
    &::placeholder {
      color: var(--dsx-color-interaction-text-inactive);
      font-weight: var(--dsx-font-weight-regular);
    }
    &[type='button'] {
      cursor: text;
    }
  }

  & > .dsx-Icon {
    color: var(--dsx-color-interaction-text-inactive);
  }

  .dsx-IconButton {
    position: relative;
    margin: 0 -4px;
  }
  // INPUT OPTION BUTTON
  .dsx-Input-option {
    opacity: 0;
  }

  &:active,
  &:has(:focus(:not(:read-only)), [aria-expanded='true']) {
    border-color: var(--dsx-input-focus-border-color);
    .dsx-Input-option {
      opacity: 1;
    }
  }
  &:has(:disabled) {
    color: var(--dsx-color-interaction-text-disabled);
    border-color: var(--dsx-color-interaction-border-disabled);
    background-color: var(--dsx-color-interaction-fill-disabled);
    &::after {
      display: none;
    }
  }
  // &:has([readonly]) {
  //   background-color: var(--dsx-color-interaction-fill-disabled);
  // }
  &.is-invalid {
    border-color: var(--dsx-color-negative-border-default) !important;
  }

  // VARIANT
  &--filled {
    --dsx-input-border-color: transparent;
    --dsx-input-background-color: #f8f9fb;
  }

  // SIZE
  &--small {
    --dsx-input-height: var(--dsx-scale-4);
    --dsx-input-padding: 0 var(--dsx-space-2);
    --dsx-input-font-size: var(--dsx-font-size-small);
  }
  &--large {
    --dsx-input-height: var(--dsx-scale-6);
    --dsx-input-padding: 0 var(--dsx-space-4);
    --dsx-icon-size: var(--dsx-scale-3);
  }
  &--xlarge {
    --dsx-input-height: var(--dsx-scale-7);
    --dsx-input-padding: 0 var(--dsx-space-5);
    --dsx-input-border-radius: var(--dsx-radius-xlarge);
    --dsx-input-font-size: var(--dsx-font-size-body2);
    --dsx-icon-size: var(--dsx-scale-3);
  }
}

.dsx-InputHashtag {
  width: 100%;
  height: auto;
  padding: var(--dsx-space-4) var(--dsx-space-5);
  flex-wrap: wrap;
  .dsx-Input-field {
    min-height: rem(24px);
    padding: 0;
  }
}

.dsx-InputEditTable {
  position: relative;
  &-field {
    font-size: var(--dsx-font-size-body1);
    font-weight: var(--dsx-font-weight-medium);
    outline: 0;
    &.is-placeholder {
      color: var(--dsx-color-interaction-text-inactive);
      font-weight: var(--dsx-font-weight-regular);
    }
  }
  &-edit {
    position: absolute;
    top: 0;
    right: 0;
  }
  &-field:focus ~ &-edit {
    opacity: 0;
  }
}
