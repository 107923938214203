:root, [data-theme="light"] {
  // Colors
  @each $attr, $value in $theme-colors {
    --ds-color-#{$attr}: #{$value};
  }

  // Borders
  @each $attr, $value in $borders {
    --ds-#{$attr}: #{$value};
  }

  // Fonts
  @each $attr, $value in $fonts {
    --ds-#{$attr}: #{$value};
  }
  
  // Shadows
  @each $attr, $value in $shadows {
    --ds-#{$attr}: #{$value};
  }

  //Transitions
  @each $attr, $value in $transitions {
    --ds-#{$attr}: #{$value};
  }

  // Icons
	@each $attr, $value in $icons {
		--ds-#{$attr}: #{$value};
	}
}

@if $config-dark-mode {
  @include color-mode(dark, true) {
    color-scheme: dark;
    --ds-color-white: #000;
    --ds-color-black: #fff;
    // Colors
    @each $attr, $value in $theme-colors-dark {
      --ds-#{$attr}: #{$value};
    }
  }
}