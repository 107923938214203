// 채팅 화면
.chat-room {
  position: relative;
  display: flex;
  flex-direction: column;
  // width: rem(760px);
  width: 50vw;
  min-width: rem(760px);
  max-width: rem(1148px);
  min-height: 100%;
  margin: 0 auto;
  // padding: 0 32px;
  padding: 0 48px;
  box-sizing: content-box;
}

// 채팅 인사이트
.chat-visual {
  padding: 80px 0;
  text-align: center;
  .visual {
    height: rem(140px);
    margin-bottom: 20px;
    img {
      height: 100%;
    }
  }
  .title {
    margin: 0;
    color: var(--ds-color-text-strong);
    font-size: var(--ds-font-size-display1);
    font-weight: var(--ds-font-weight-normal);
    strong {
      font-weight: var(--ds-font-weight-bold);
    }
  }
  .desc {
    margin: 8px 0 0 0;
    color: var(--ds-color-text-subtle);
    font-size: var(--ds-font-size-lg);
  }
}

// 추천키워드
.chat-keyword {
  max-width: rem(760px);
  margin: 0 auto 160px;
  .keyword-list {
    @include flex(flex, center, center);
    flex-wrap: wrap;
    gap: 12px;
    .keyword-link {
      position: relative;
      @include flex(flex, center, center);
      gap: 8px;
      padding: 7px 16px;
      color: var(--ds-color-text-strong);
      font-size: var(--ds-font-size-lg);
      font-weight: var(--ds-font-weight-medium);
      border: 1px solid var(--ds-color-border-subtler);
      border-radius: var(--ds-border-radius-max);
      white-space: nowrap;
      .tag {
        padding: 0 8px;
        color: var(--ds-color-static-white);
        font-size: rem(13px);
        line-height: rem(20px);
        background-color: var(--ds-color-primary);
        border-radius: var(--ds-border-radius-max);
      }
    }
    .keyword-refresh {
      width: rem(32px);
      height: rem(32px);
      border-radius: 100%;
      background-color: var(--ds-color-fill-subtler);
    }
  }
}

// 대화창
.chat-history {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 32px 0 80px;
  &-item {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}
.chat-message {
  .msg-profile {
    display: flex;
    align-items: center;
    gap: 8px;
    .user-avatar {
      border-radius: 100%;
      box-shadow: inset 0 0 0 1px var(--ds-color-border-subtler);
    }
    .name {
      color: var(--ds-color-text-strong);
      font-size: var(--ds-font-size-lg);
    }
  }
  .msg-content {
    margin-top: 4px;
    padding-left: calc(rem(32px) + 8px);
    font-size: var(--ds-font-size-lg);
    line-height: var(--ds-line-height-reading);
    > *:first-child {
      margin-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
}
// 입력창
.chat-bottom {
  position: sticky;
  bottom: 0;
  margin-top: auto;
  padding: 0 0 26px;
  background-color: var(--ds-color-surface-default);
  .go-end {
    position: relative;
    height: 80px;
    margin-top: -80px;
    background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    .ds-control {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      color: var(--ds-color-text-inactive);
      border: 1px solid var(--ds-color-border-inactive);
      border-radius: 100%;
      background-color: var(--ds-color-surface-paper);
      &::after {
        content: '';
        width: rem(28px);
        -webkit-mask-image: var(--ds-icon-arrow);
      }
    }
  }
}
.chat-input {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 15px 15px 23px;
  gap: 16px;
  border: 1px solid var(--ds-color-border-subtler);
  border-radius: var(--ds-border-radius-xxl);
  &:has(.input-text:not(:disabled):hover) {
    border-color: var(--ds-color-border-subtle);
  }
  &:has(.input-text:focus) {
    border-color: var(--ds-color-primary);
  }
  .input-text {
    width: 100%;
    margin: 0;
    padding: 0;
    color: var(--ds-color-text-strong);
    font-size: var(--ds-font-size-lg);
    border: 0;
    outline: 0;
    background: none;
  }
  .input-option {
    display: flex;
    align-items: center;
    gap: 8px;
    .option-item {
      position: relative;
    }
  }
  .enter-area {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: auto;
    .chat-send {
      width: rem(32px);
      height: rem(32px);
      padding: 4px;
      color: var(--ds-color-text-inverse);
      border-radius: 100%;
      background-color: var(--ds-color-primary);
      .ds-icon {
        width: 100%;
      }
      &:disabled {
        background-color: var(--ds-color-fill-default);
      }
    }
  }
}
.chat-caution {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

// 플러그인
.chat-plugin {
  position: static !important;
  .model-name {
    display: none;
  }
  .ds-popover {
    bottom: calc(100% + 8px);
    left: 0;
  }
}
// 챗모델 선택
.chat-select {
  position: relative;
  .ds-popover {
    bottom: calc(100% + 8px);
    right: 0;
  }
}
.ds-popover[role='listbox'] {
  width: rem(360px);
}

