@import '../config/index.scss';

.page-area {
  height: 100%;
  overflow-y: auto; 
  &:not(:hover)::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
.page-inner {
  width: 1148px;
  margin: 0 auto;
  padding: 80px 32px;
  box-sizing: content-box;
}

.page-title {
  text-align: center;
  .visual {
    height: rem(140px);
    margin-bottom: 20px;
    img {
      height: 100%;
    }
  }
  .title {
    margin: 0;
    font-size: var(--dsx-font-size-heading1);
    font-weight: var(--dsx-font-weight-regular);
    strong {
      font-weight: var(--dsx-font-weight-bold);
    }
  }
  .desc {
    margin: 8px 0 0 0;
    color: var(--dsx-color-neutral-text-alternative);
    font-size: var(--dsx-font-size-large);
  }
}
