@import '../config/index.scss';

.dsx-TabList {
  --dsx-tab-height: #{rem(44px)};
  --dsx-tab-padding-y: 10px;
  --dsx-tab-padding-x: var(--dsx-space-4);
  --dsx-tab-selected-color: var(--dsx-color-primary-fill-default);
  --dsx-tab-font-size: var(--dsx-font-size-title3);
  --dsx-icon-size: #{rem(20px)};
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: var(--dsx-space-7);
  .dsx-Tab {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--dsx-space-1);
    height: var(--dsx-tab-height);
    padding: var(--dsx-tab-padding-y) var(--dsx-tab-padding-x);
    border-radius: var(--dsx-radius-base);
    &-name {
      padding: 0 var(--dsx-space-1);
    }
  }
  &.is-full .dsx-Tab {
    flex: 1 1 0;
  }

  // variant
  &--text {
    box-shadow: inset 0px -1px 0px var(--dsx-color-neutral-border-alternative);
    .dsx-Tab {
      color: var(--dsx-color-interaction-text-inactive);
      font-size: var(--dsx-tab-font-size);
      font-weight: var(--dsx-font-weight-medium);
      &[aria-selected='true'] {
        color: var(--dsx-tab-selected-color);
        font-weight: var(--dsx-font-weight-bold);
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 3px;
          background-color: var(--dsx-tab-selected-color);
          border-radius: var(--dsx-radius-max);
        }
      }
      &:hover {
        color: var(--dsx-tab-selected-color);
      }
      &:disabled {
        color: var(--dsx-color-interaction-text-disabled);
      }
    }
  }
  &--box {
    gap: var(--dsx-space-3);
    .dsx-Tab {
      color: var(--dsx-color-neutral-text-neutral);
      font-weight: var(--dsx-font-weight-medium);
      box-shadow: inset 0 0 0 1px var(--dsx-color-interaction-border-inactive);
      &[aria-selected='true'] {
        color: var(--dsx-color-static-white);
        font-weight: var(--dsx-font-weight-semibold);
        background-color: var(--dsx-tab-selected-color);
        box-shadow: none;
      }
      &:disabled {
        color: var(--dsx-color-interaction-text-disabled);
        box-shadow: inset 0 0 0 1px var(--dsx-color-interaction-border-disabled);
      }
      @include interaction();
    }
  }

  // size
  &--small {
    --dsx-tab-height: #{rem(32px)};
    --dsx-tab-padding-y: var(--dsx-space-2);
    --dsx-tab-padding-x: var(--dsx-space-3);
    --dsx-tab-font-size: var(--dsx-font-size-base);
    --dsx-icon-size: #{rem(16px)};
  }
}

.dsx-TabPanel {
  &[data-state='show'] {
    display: block;
  }
  &[data-state='hide'] {
    display: none;
  }
}
