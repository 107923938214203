// scrollbar
::-webkit-scrollbar {
  height: .5rem;
  width: .5rem;
}
::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  border-radius: var(--ds-border-radius-max);
  border: 2px solid transparent;
  background-clip: padding-box;
  background-color: var(--ds-color-fill-strong);
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: var(--ds-border-radius-max);
}

// a11y
.blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

// text
.txt-left {
  text-align: left !important;
}
.txt-right {
  text-align: right !important;
}
.txt-center {
  text-align: center !important;
}
.txt-point {
  color: var(--ds-color-primary) !important;
}
.txt-info {
  margin: 12px 0;
}
.txt-caution {
  color: var(--ds-color-caution);
  font-size: rem(13px);
}
.txt-notify {
  margin: 10px 0;
  color: var(--ds-color-notice);
}
.txt-error {
  margin-top: 4px;
  color: var(--ds-color-negative);
}
.txt-link {
  color: var(--ds-color-primary);
  text-decoration: underline;
  text-underline-position: under;
  &:hover {
    color: var(--ds-color-primary);
    text-decoration: underline !important;
  }
}
.txt-length {
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  margin: 0;
  font-size: rem(13px);
}

// title
.tit-h1 {
  color: var(--ds-color-text-strong);
  font-size: var(--ds-font-size-h1);
  font-weight: var(--ds-font-weight-semibold);
}
.tit-h2 {
  color: var(--ds-color-text-strong);
  font-size: var(--ds-font-size-h2);
  font-weight: var(--ds-font-weight-semibold);
}
.tit-h3 {
  color: var(--ds-color-text-strong);
  font-size: var(--ds-font-size-h3);
  font-weight: var(--ds-font-weight-semibold);
}
.tit-h4 {
  color: var(--ds-color-text-strong);
  font-size: var(--ds-font-size-lg);
  font-weight: var(--ds-font-weight-semibold);
  line-height: 1.5;
}
.title-area {
  @include flex(flex,space-between,stretch);
  margin-bottom: 16px;
  &:first-child {
    margin-top: 0;
  }
  h2, h3, h4 {
    margin: 0;
  }
}

// nodata
.no-data {
  @include flex(flex,center,center,column);
  gap: 12px;
  height: 100%;
  margin: 0;
  color: var(--ds-color-text-subtle);
  &::before {
    content: '';
    width: rem(32px);
    height: rem(32px);
    background: var(--ds-icon-error) 0 0 no-repeat;
    background-size: 100%;
  }
}