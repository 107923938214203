/* button */
.ds-button {
  --ds-button-height: #{rem(40px)};
  --ds-button-spacer: 8px 16px;
  --ds-button-font-color: var(--ds-color-text-strong);
  --ds-button-font-size: var(--ds-font-size-lg);
  --ds-button-border-color: var(--ds-color-border-subtle);
  --ds-button-border-radius: var(--ds-border-radius-base);
  --ds-button-background-color: transparent;
  position: relative;
  @include flex(flex, center, center);
  gap: 4px;
  height: var(--ds-button-height);
  padding: 0 16px;
  border: 0;
  border-radius: 0;
  background: none;
  appearance: none;
  color: var(--ds-button-font-color);
  border-radius: var(--ds-button-border-radius);
  background-color: var(--ds-button-background-color);
  box-shadow: inset 0 0 0 1px var(--ds-button-border-color);
  @include action(var(--ds-button-border-radius));
  &:disabled {
    --ds-button-font-color: var(--ds-color-text-disabled);
    --ds-button-border-color: var(--ds-color-border-disabled);
    --ds-button-background-color: var(--ds-color-fill-disabled);
  }
}
.ds-button-primary {
  --ds-button-font-color: var(--ds-color-text-inverse);
  --ds-button-border-color: transparent !important;
  --ds-button-background-color: var(--ds-color-primary);
}
.ds-button-secondary {
  --ds-button-border-color: transparent !important;
  --ds-button-background-color: var(--ds-color-fill-subtler);
}

// 버튼 정렬
.ds-button-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 16px;
  .ds-button {
    flex: 1 1 0;
  }
  &.stack-r {
    justify-content: flex-end;
    gap: 8px;
    .ds-button {
      flex: 0 1 rem(83px);
    }
  }
  &.stack-l {
    justify-content: flex-start;
    gap: 8px;
    .ds-button {
      flex: 0 1 rem(83px);
    }
  }
}