.ds-icon {
  display: inline-block;
  width: 1em;
  aspect-ratio: 1;
  background-color: currentColor;
  -webkit-mask-size: 100%;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  vertical-align: rem(-2px);
}

.ds-icon-menu {
  -webkit-mask-image: var(--ds-icon-menu);
}
.ds-icon-profile {
  -webkit-mask-image: var(--ds-icon-profile);
}
.ds-icon-profile-fill {
  -webkit-mask-image: var(--ds-icon-profile-fill);
}
.ds-icon-info {
  -webkit-mask-image: var(--ds-icon-info);
}
.ds-icon-pause {
  -webkit-mask-image: var(--ds-icon-pause);
}
.ds-icon-close {
  -webkit-mask-image: var(--ds-icon-close);
}
.ds-icon-remove {
  -webkit-mask-image: var(--ds-icon-remove);
}
.ds-icon-refresh {
  -webkit-mask-image: var(--ds-icon-refresh);
}
.ds-icon-setting {
  -webkit-mask-image: var(--ds-icon-setting);
}
.ds-icon-bookmark {
  -webkit-mask-image: var(--ds-icon-bookmark);
}
.ds-icon-pin {
  -webkit-mask-image: var(--ds-icon-pin);
}
.ds-icon-arrow {
  -webkit-mask-image: var(--ds-icon-arrow);
}
.ds-icon-next {
  -webkit-mask-image: var(--ds-icon-arrow);
  transform: rotate(-90deg);
}
.ds-icon-prev {
  -webkit-mask-image: var(--ds-icon-arrow);
  transform: rotate(-270deg);
}
.ds-icon-logout {
  -webkit-mask-image: var(--ds-icon-logout);
}
.ds-icon-clear {
  -webkit-mask-image: var(--ds-icon-clear);
}
.ds-icon-password-on {
  -webkit-mask-image: var(--ds-icon-password-on);
}
.ds-icon-password-off {
  -webkit-mask-image: var(--ds-icon-password-ff);
}
.ds-icon-check {
  -webkit-mask-image: var(--ds-icon-check);
}
.ds-icon-prompt {
  -webkit-mask-image: var(--ds-icon-prompt);
}
.ds-icon-attach {
  -webkit-mask-image: var(--ds-icon-attach);
}
.ds-icon-templete {
  -webkit-mask-image: var(--ds-icon-templete);
}
.ds-icon-code {
  -webkit-mask-image: var(--ds-icon-code);
}
.ds-icon-recycle {
  -webkit-mask-image: var(--ds-icon-recycle);
}
.ds-icon-add {
  -webkit-mask-image: var(--ds-icon-add);
}
.ds-icon-add-fill {
  -webkit-mask-image: var(--ds-icon-add-fill);
}
.ds-icon-more {
  -webkit-mask-image: var(--ds-icon-more);
}
.ds-icon-edit {
  -webkit-mask-image: var(--ds-icon-edit);
}
.ds-icon-delete {
  -webkit-mask-image: var(--ds-icon-delete);
}
.ds-icon-checkbox {
  -webkit-mask-image: var(--ds-icon-checkbox);
}
.ds-icon-error {
  -webkit-mask-image: var(--ds-icon-error);
}
.ds-icon-send {
  -webkit-mask-image: var(--ds-icon-send);
}
.ds-icon-works {
  -webkit-mask-image: var(--ds-icon-works);
}