@import '../config/index.scss';

.dsx-Loading {
  --dsx-loading-scale: var(--dsx-scale-5);
  position: relative;
  @include flex(inline-flex, center, center, column);
  z-index: 0;

  &-spinner {
    text-align: center;
  
    .spinner-image {
      width: var(--dsx-loading-scale);
      height: var(--dsx-loading-scale);
      vertical-align: top;
      .path1 {
        fill: var(--dsx-color-neutral-border-neutral);
      }
      .path2 {
        transform-origin: center;
        animation: dsx-loading-rotate 0.75s infinite linear;
        fill: var(--dsx-color-primary-fill-default);
      }
    }
    .spinner-text {
      font-weight: var(--dsx-font-weight-medium);
      font-size: calc(var(--dsx-loading-scale) / 2.2);
    }
  }

  // size
  &--size1 {
    --dsx-loading-scale: var(--dsx-scale-3);
  }
  &--size2 {
    --dsx-loading-scale: var(--dsx-scale-4);
  }
  &--size3 {
    --dsx-loading-scale: var(--dsx-scale-5);
  }
  &--size4 {
    --dsx-loading-scale: var(--dsx-scale-6);
  }
  &--size5 {
    --dsx-loading-scale: var(--dsx-scale-7);
  }

  // position
  &[data-position='absolute'] {
    position: absolute;
    inset: 0;
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: var(--dsx-color-neutral-surface-default);
      opacity: 0.7;
      z-index: -1;
    }
  }
  &[data-position='fixed'] {
    position: fixed;
    inset: 0;
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: var(--dsx-color-neutral-surface-default);
      opacity: 0.7;
      z-index: -1;
    }
  }
}

@keyframes dsx-loading-rotate {
  100% {
    transform: rotate(360deg);
  }
}
