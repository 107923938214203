@import '../config/index.scss';

// 팝업 공통
.dsx-ModalOverlay {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--dsx-space-7);
  z-index: 1;
  &::before {
    content: '';
    position: fixed;
    inset: 0;
    background-color: var(--dsx-color-interaction-dimmer);
  }
  &[data-state='show'] {
    &::before {
      animation: dsx-fade-in var(--dsx-transition-popover) forwards;
    }
    .dsx-Modal {
      animation: dsx-modal-show var(--dsx-transition-popover) forwards;
    }
  }
  &[data-state='hide'] {
    &::before {
      animation: dsx-fade-out var(--dsx-transition-popover) forwards;
    }
    .dsx-Modal {
      animation: dsx-modal-hide var(--dsx-transition-popover) forwards;
    }
  }
}

// 콘텐츠 팝업
.dsx-Dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--dsx-space-4);
  width: rem(600px);
  max-width: 100%;
  max-height: 100%;
  padding: calc(var(--dsx-space-7) - var(--dsx-stroke-base));
  border: var(--dsx-stroke-base) solid var(--dsx-color-neutral-border-default);
  border-radius: var(--dsx-radius-xlarge);
  background: var(--dsx-color-neutral-surface-default);
  box-shadow: var(--dsx-shadow-4);
  &-close {
    --dsx-icon-size: #{rem(20px)};
    --dsx-icon-color: var(--dsx-color-neutral-icon-neutral);
  }
  &-header {
    display: flex;
    flex-wrap: wrap;
    .dsx-Heading {
      max-width: calc(100% - 48px);
    }
    .dsx-Dialog-close {
      margin-left: auto;
    }
    .dsx-Text {
      width: 100%;
      margin-top: var(--dsx-space-2);
      color: var(--dsx-color-neutral-text-alternative);
    }
  }
  &-content {
    min-height: rem(160px);
    max-height: 100%;
    overflow: auto;
    border-radius: var(--dsx-radius-large);
  }
  &-footer {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: var(--dsx-space-3);
    .dsx-Button {
      min-width: rem(84px);
    }
  }
  // size
  &--small {
    width: rem(320px);
    .dsx-Button {
      flex: 1;
      min-width: none;
    }
  }
  &--large {
    width: rem(900px);
  }
}

// 시스템 팝업
.dsx-AlertDialog {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--dsx-space-6);
  width: rem(320px);
  max-width: 100%;
  padding: calc(var(--dsx-space-7) - var(--dsx-stroke-base));
  border: var(--dsx-stroke-base) solid var(--dsx-color-neutral-border-default);
  border-radius: var(--dsx-radius-xlarge);
  background: var(--dsx-color-neutral-surface-default);
  box-shadow: var(--dsx-shadow-4);
  &-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: rem(60px);
    padding: 4px 0;
    text-align: center;
  }
  &-footer {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: var(--dsx-space-3);
    .dsx-Button {
      flex: 1 1 0;
    }
  }
}
