@import '../config/index.scss';

// 기본메뉴
.nav {
  .menu-list {
    @include flex(flex, center, center);
    gap: var(--dsx-gnb-menu-gap);
    padding: 0;
    list-style: none;
    .dth1 {
      @include flex(flex, center, center);
      height: rem(32px);
      padding: 2px 20px;
      color: var(--dsx-color-inverse-text);
      font-size: var(--dsx-font-size-large);
      border-radius: var(--dsx-radius-max);
      transition: var(--dsx-transition-fade);
      &.is-active,
      &:hover {
        color: var(--dsx-color-static-white);
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

// 프로필
// .profile {
//   position: relative;
//   &-control {
//     .ds-avatar {
//       background: var(--dsx-icon-profile);
//       background-size: 100%;
//     }
//     &::after {
//       content: '';
//       -webkit-mask-image: var(--dsx-icon-arrow);
//     }
//   }
// }
// .profile-popover {
//   right: 0;
//   transform-origin: top right;
//   width: rem(280px);
//   padding: 20px;
//   .profile-user {
//     @include flex(flex, center, center, column);
//     .ds-avatar {
//       width: rem(72px);
//       margin-bottom: 16px;
//       background: var(--dsx-icon-profile-fill);
//       background-size: 100%;
//     }
//     .user-name {
//       @include flex(flex, center, center);
//       gap: 4px;
//       .ds-tag {
//         height: rem(24px);
//         font-size: var(--dsx-font-size-large);
//         font-weight: var(--dsx-font-weight-medium);
//       }
//     }
//     .user-team {
//       margin-top: 12px;
//       font-weight: var(--dsx-font-weight-medium);
//       white-space: nowrap;
//     }
//     .user-id {
//       margin-top: 8px;
//       padding: 2px 12px;
//       border-radius: var(--dsx-radius-base);
//       background-color: var(--dsx-color-neutral-fill-assistive);
//     }
//   }
//   .profile-menu {
//     margin-top: 40px;
//     .ds-control {
//       justify-content: flex-start;
//       width: 100%;
//       padding: 12px 20px;
//       border-radius: var(--ds-radius-large);
//       background-color: var(--ds-color-neutral-fill-assistive);
//       .ds-icon {
//         width: rem(20px);
//       }
//     }
//   }
// }
