.ds-select {
  --ds-select-position-top: calc(100% + 2px);
  --ds-select-position-left: auto; 
  --ds-select-position-right: 0;
  --ds-select-position-bottom: auto;
  --ds-select-transform-origin: top center;
  position: relative;
  display: inline-block;
  [role="combobox"] {
    cursor: pointer;
  }
  .select-list {
    position: absolute;
    top: var(--ds-select-position-top);
    left: var(--ds-select-position-left);
    right: var(--ds-select-position-right);
    bottom: var(--ds-select-position-bottom);
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: rem(120px);
    max-height: rem(230px);
    overflow-y: auto;
    padding: 8px;
    border-radius: var(--ds-border-radius-xl);
    border: 1px solid var(--ds-color-border-subtle);
    background: var(--ds-color-white);
    box-shadow: var(--ds-box-shadow-s2);
    visibility: hidden;
    transform-origin: var(--ds-select-transform-origin);
    &::-webkit-scrollbar-button {
      height: 4px;
    }
    z-index: $z-index-picker;
    &.is-show {
      visibility: visible;
    }
    &[data-state="show"] {
      animation: fadeInUp var(--ds-transition-popover);
      visibility: visible;
    }
    &[data-state="hide"] {
      animation: fadeOutDown var(--ds-transition-popover);
      visibility: hidden;
    }
  }
  .select-option {
    flex: 0 0 auto;
    position: relative;
    @include flex(flex, flex-start, center);
    gap: 8px;
    width: 100%;
    height: rem(32px);
    margin: 0;
    padding: 0 12px;
    border: 0;
    background: none;
    appearance: none;
    color: var(--ds-color-text-strong);
    text-align: left;
    white-space: nowrap;
    &::before {
      content: '';
      flex: 0 0 auto;
      width: rem(20px);
      height: rem(20px);
    }
    &[aria-selected="true"] {
      color: var(--ds-color-primary);
      &::before {
        -webkit-mask: var(--ds-icon-check);
        -webkit-mask-size: 100%;
        background-color: currentColor;
      }
    }
    &:hover, &:focus, &.is-current {
      background-color: var(--ds-color-action-hover);
      border-radius: var(--ds-border-radius-base);
    }
  }
  &.select-l {
    --select-position-top: calc(100% + 2px);
    --ds-select-position-left: 0;
    --ds-select-position-right: auto;
    --ds-select-transform-origin: top left;
  }
  &.select-r {
    --ds-select-position-top: calc(100% + 2px);
    --ds-select-position-left: auto; 
    --ds-select-position-right: 0;
    --ds-select-position-bottom: auto;
    --ds-select-transform-origin: top right;
  }
  &:has(> .ds-input) {
    --ds-select-position-top: calc(100% + 2px);
    --ds-select-position-left: 0; 
    --ds-select-position-right: 0;
    --ds-select-position-bottom: auto;
    --ds-select-transform-origin: top center;
  }
}