@import '../config/index.scss';

.dsx-TextList {
  padding-left: 18px;
  & > li {
    position: relative;
    margin-top: 8px;
    &::before {
      display: inline-block;
      width: 18px;
      margin-left: -18px;
    }
  }

  // variant
  &--disc {
    list-style: disc;
  }
  &--dash > li::before {
    content: '-';
    text-indent: 2px;
  }
  &--asterisk > li::before {
    content: '*';
    line-height: 1;
    vertical-align: -0.15em;
    text-indent: 2px;
  }
  &--caution > li::before {
    content: '※';
  }

  &:where(ol) {
    counter-reset: dsx-ol;
    & > li::before {
      counter-increment: dsx-ol;
    }
    &[class*='Circle'] > li::before {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 13px;
      height: 13px;
      margin-right: 5px;
      line-height: 1;
      font-weight: var(--dsx-font-weight-medium);
      vertical-align: middle;
      box-shadow: inset 0 0 0 0.8px currentColor;
      border-radius: 100%;
    }
  }
  &--decimal > li::before {
    content: counter(dsx-ol, decimal) '.';
  }
  &--decimalCircle > li::before {
    content: counter(dsx-ol, decimal);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
    margin-right: 5px;
    font-size: 10px;
    line-height: 1;
    font-weight: var(--dsx-font-weight-medium);
    vertical-align: middle;
    box-shadow: inset 0 0 0 0.8px currentColor;
    border-radius: 100%;
  }
  &--hangul > li::before {
    content: counter(dsx-ol, hangul) '.';
  }
  &--hangulCircle > li::before {
    content: counter(dsx-ol, hangul-consonant);
    font-size: 11px;
  }
  &--alpha > li::before {
    content: counter(dsx-ol, upper-alpha) '.';
  }
  &--alphaCircle > li::before {
    content: counter(dsx-ol, lower-alpha);
    font-size: 11px;
  }

  @-moz-document url-prefix() {
    &--hangul > li::before {
      content: counter(dsx-ol, -moz-hangul) '.';
    }
    &--hangulCircle > li::before {
      content: counter(dsx-ol, -moz-hangul-consonant);
    }
  }
}
