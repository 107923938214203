::placeholder {
  color: var(--ds-color-text-inactive);
  font-weight: var(--ds-font-weight-normal);
}

.ds-input {
  --ds-input-height: #{rem(40px)};
  --ds-input-padding: 0 12px;
  --ds-input-border-color: var(--ds-color-border-subtler);
  --ds-input-border-radius: var(--ds-border-radius-base);
  --ds-input-background-color: var(--ds-surface-default);
  --ds-input-focus-border-color: var(--ds-color-primary);
  @include flex(inline-flex, flex-start, center);
  width: rem(124px);
  height: var(--ds-input-height);
  padding: var(--ds-input-padding);
  color: var(--ds-color-text-strong);
  border: 1px solid var(--ds-input-border-color);
  border-radius: var(--ds-input-border-radius);
  background-color: var(--ds-input-background-color);
  cursor: text;
  .input-field {
    appearance: none;
    width: 100%;;
    height: 100%;
    margin: 0;
    padding-right: 10px;
    font-weight: var(--ds-font-weight-medium);
    border: 0;
    background: none;
    outline: 0;
  }
  .input-clear {
    position: relative;
    display: none;
    width: rem(20px);
    height: rem(20px);
    @include text-hidden;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-mask: var(--ds-icon-clear) 50% 50% no-repeat;
      -webkit-mask-size: 100%;
      background-color: currentColor;
    }
  }
  &:has(:focus) {
    border-color: var(--ds-input-focus-border-color);
  }
  &:has([readonly]) {
    background-color: var(--ds-color-fill-disabled);
  }
}
.ds-input-filled {
  --ds-input-border-color: transparent;
  --ds-input-background-color: var(--ds-color-fill-subtler);
}