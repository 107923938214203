@import '../config/index.scss';

@keyframes dsx-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes dsx-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes dsx-slide-from-top {
  from {
    transform: translateY(4px) scale(0.97);
  }
  to {
    transform: translateY(0) scale(1);
  }
}

@keyframes dsx-slide-to-top {
  from {
    transform: translateY(0) scale(1);
  }
  to {
    transform: translateY(4px) scale(0.97);
  }
}

@keyframes dsx-slide-from-bottom {
  from {
    transform: translateY(-4px) scale(0.97);
  }
  to {
    transform: translateY(0) scale(1);
  }
}

@keyframes dsx-slide-to-bottom {
  from {
    transform: translateY(0) scale(1);
  }
  to {
    transform: translateY(-4px) scale(0.97);
  }
}

@keyframes dsx-slide-from-left {
  from {
    transform: translateX(4px) scale(0.97);
  }
  to {
    transform: translateX(0) scale(1);
  }
}

@keyframes dsx-slide-to-left {
  from {
    transform: translateX(0) scale(1);
  }
  to {
    transform: translateX(4px) scale(0.97);
  }
}

@keyframes dsx-slide-from-right {
  from {
    transform: translateX(-4px) scale(0.97);
  }
  to {
    transform: translateX(0) scale(1);
  }
}

@keyframes dsx-slide-to-right {
  from {
    transform: translateX(0) scale(1);
  }
  to {
    transform: translateX(-4px) scale(0.97);
  }
}

@keyframes dsx-modal-show {
  from {
    opacity: 0;
    transform: translateY(5px) scale(0.97);
    visibility: hidden;
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
    visibility: visible;
  }
}

@keyframes dsx-modal-hide {
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
    visibility: visible;
  }

  to {
    opacity: 0;
    transform: translateY(5px) scale(0.99);
    visibility: hidden;
  }
}

@keyframes dsx-fab-show {
  from {
    opacity: 0;
    bottom: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes dsx-fab-hide {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    bottom: 0;
    visibility: hidden;
  }
}

@keyframes dsx-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dsx-skeleton-loading {
  100% {
    background-position: -100% 0;
  }
}
