@import '../config/index.scss';

/* Checkbox */
.dsx-Checkbox {
  --dsx-checkbox-size: #{rem(16px)};
  --dsx-checkbox-margin: 2px;
  --dsx-checkbox-border-color: var(--dsx-color-normal-border-neutral);
  --dsx-checkbox-checked-border-color: var(--dsx-color-primary-fill-default);
  --dsx-checkbox-checked-background-color: var(--dsx-color-primary-fill-default);
  --dsx-checkbox-checked-mask-image: var(--dsx-icon-checked);
  display: inline-flex;
  align-items: flex-start;
  vertical-align: middle;
  &-input {
    flex: 0 0 auto;
    position: relative;
    width: var(--dsx-checkbox-size);
    height: var(--dsx-checkbox-size);
    margin: var(--dsx-checkbox-margin) 0;
    border: 1px solid var(--dsx-checkbox-border-color);
    border-radius: 4px;
    appearance: none;
    cursor: pointer;
    @include interaction(-1px);
    &:checked,
    &.is-indeterminate:not(:checked) {
      border-color: var(--dsx-checkbox-checked-border-color);
      background-color: var(--dsx-checkbox-checked-background-color);
      &::before {
        content: '';
        position: absolute;
        inset: -1px;
        background-color: var(--dsx-color-static-white);
        -webkit-mask: var(--dsx-checkbox-checked-mask-image) 50% 50% no-repeat;
        -webkit-mask-size: 100%;
      }
    }
    &.is-indeterminate:not(:checked) {
      --dsx-checkbox-checked-mask-image: var(--dsx-icon-indeterminate);
    }
    &:disabled {
      border-color: var(--dsx-color-interaction-border-disabled);
      background-color: transparent;
      cursor: default;
      &::before {
        background-color: var(--dsx-color-interaction-border-disabled);
      }
    }
  }
  &-label {
    padding: 0 0 0 8px;
  }
  &:has(:disabled) &-label {
    color: var(--dsx-color-interaction-text-disabled);
    cursor: default;
  }
  &.is-invalid {
    --dsx-checkbox-border-color: var(--dsx-color-negative-fill-default);
    --dsx-checkbox-checked-border-color: var(--dsx-color-negative-fill-default);
    --dsx-checkbox-checked-background-color: var(--dsx-color-negative-fill-default);
  }

  // SIZE
  &--large {
    --dsx-checkbox-size: #{rem(18px)};
    --dsx-checkbox-margin: 2px;
    font-size: var(--dsx-font-size-body2);
  }
}

.dsx-CheckboxGroup {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  .dsx-Checkbox {
    margin-right: 8px;
  }
}
