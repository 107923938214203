@import '../config/index.scss';

.dsx-DatepickerTrigger {
  width: rem(280px);
  background-color: var(--dsx-color-neutral-surface-default) !important;
}
.dsx-DatePicker {
  width: rem(280px);
  margin: 4px auto;
  border-radius: var(--dsx-radius-base);
  background-color: var(--dsx-color-white);
  box-shadow: var(--dsx-shadow-3);

  &-dayNames,
  &-days {
    display: grid;
    gap: 0 8px;
    grid-template-columns: repeat(7, minmax(20px, 1fr));
  }
  &-years,
  &-months {
    display: grid;
    gap: 24px 32px;
    grid-template-columns: repeat(3, minmax(20px, 1fr));
    padding: 24px 6px;
  }

  &-header {
    display: flex;
    justify-content: center;
    height: rem(44px);
    padding: 4px 16px;
    border-bottom: 1px solid var(--dsx-color-neutral-border-alternative);
  }
  &-label {
    height: rem(36px);
    padding: 0 8px;
    font-size: var(--dsx-font-size-body2);
    font-weight: var(--dsx-font-weight-semibold);
    &--year {
      width: rem(72px);
    }
    &--month {
      width: rem(60px);
    }
  }
  &-arrow {
    color: var(--dsx-color-normal-icon-default);
    &:first-child {
      margin-right: auto;
    }
    &:last-child {
      margin-left: auto;
    }
  }

  &-body {
    position: relative;
    padding: 12px 18px;
    text-align: center;
    z-index: 0;
  }
  &-dayNames {
    height: rem(32px);
    align-items: center;
    color: var(--dsx-color-neutral-text-alternative);
    font-size: var(--dsx-font-size-caption1);
  }
  &-day {
    @include flex(flex, center, center);
    width: rem(28px);
    height: rem(28px);
    margin: 4px auto;
    color: var(--dsx-color-neutral-text-neutral);
    font-size: var(--dsx-font-size-label2);
    border-radius: var(--dsx-radius-max);
  }
  &-month,
  &-year {
    @include flex(flex, center, center);
    width: rem(56px);
    height: rem(36px);
    margin: 0 auto;
    font-size: var(--dsx-font-size-label1);
    font-weight: var(--dsx-font-weight-medium);
    border-radius: var(--dsx-radius-max);
  }

  &-controls {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 12px 20px;
    border-top: 1px solid var(--dsx-color-neutral-border-alternative);
  }

  [tabindex='0'] {
    position: relative;
    cursor: pointer;
    &:not(.is-selected):hover {
      color: var(--dsx-color-info-text-neutral);
    }
    @include interaction($bg: var(--dsx-color-info-fill-default));
  }
  [data-range] {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -4px;
      right: -4px;
      height: 100%;
      background: var(--dsx-color-info-fill-neutral);
      z-index: -1;
    }
    &:where([data-range='in'], [data-range='start']):nth-child(7n)::before {
      right: -18px;
    }
    &:where([data-range='in'], [data-range='end']):nth-child(7n + 1)::before {
      left: -18px;
    }
  }
  [data-range='start']::before {
    left: 50%;
  }
  [data-range='end']::before {
    right: 50%;
  }
  .is-selected {
    color: var(--dsx-color-inverse-text);
    font-weight: var(--dsx-font-weight-semibold);
    background-color: var(--dsx-color-info-fill-default);
  }
  .is-disabled,
  [aria-disabled='true'] {
    color: var(--dsx-color-interaction-text-disabled);
  }
}
