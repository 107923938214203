@import '../config/index.scss';

/* Chip */
.dsx-Chip {
  --dsx-icon-size: var(--dsx-scale-3);
  position: relative;
  display: inline-flex;
  border-radius: var(--dsx-radius-max);
  vertical-align: middle;
  &-input {
    position: absolute;
    inset: 0;
    border-radius: inherit;
  }
  &-label {
    position: relative;
    @include flex(flex, center, center);
    gap: var(--dsx-space-2);
    min-height: rem(38px);
    padding: var(--dsx-space-2) var(--dsx-space-4);
    font-weight: var(--dsx-font-weight-medium);
    line-height: var(--dsx-line-height-fit);
    border: 1px solid var(--dsx-color-interaction-border-inactive);
    border-radius: inherit;
  }
  &-input:checked + &-label {
    color: var(--dsx-color-inverse-text);
    border-color: var(--dsx-color-normal-fill-default);
    background-color: var(--dsx-color-normal-fill-default);
  }
  &-input:not(:disabled) + &-label {
    @include interaction(-1px);
  }
  &-input:disabled + &-label {
    color: var(--dsx-color-interaction-text-disabled);
    border-color: transparent;
    background-color: var(--dsx-color-interaction-fill-disabled);
    cursor: default;
    &::after {
      display: none;
    }
  }

  &--filled &-label {
    border-color: #F4F4F5;
    background-color: #F4F4F5;
  }
}

.dsx-ChipGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}