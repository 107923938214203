.ds-tag {
  @include flex(inline-flex, center, center);
  min-width: rem(40px);
  height: rem(20px);
  padding: 2px 6px;
  font-size: rem(11px);
  text-align: center;
  line-height: 1;
  border-radius: var(--ds-border-radius-base);
  background-color: var(--ds-color-fill-subtler);
  &.type-primary {
    color: var(--ds-color-primary);
  }
  &.type-positive {
    color: var(--ds-color-positive);
  }
  &.type-negative {
    color: var(--ds-color-negative);
  }
  &.type-caution {
    color: var(--ds-color-caution);
  }
  &.type-notice {
    color: #F66666;
    background-color: #FFF3F3;
  }
}