/* choice */
// 버튼 형태의 단일 선택 라디오 버튼

.ds-choice {
  --ds-choice-border-radius: var(--ds-border-radius-base);
  @include flex(inline-flex, center, center);
  gap: 4px;
  height: rem(40px);
  padding: 4px;
  border-radius: var(--ds-choice-border-radius);
  background: var(--ds-color-fill-subtler);
  .choice-item {
    position: relative;
    flex: 0 0 0;
    height: 100%;
    input {
      appearance: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }
    label {
      position: relative;
      @include flex(flex, center, center);
      min-width: rem(40px);
      height: 100%;
      padding: 6px 4px;
      color: var(--ds-color-text-strong);
      font-size: rem(13px);
      line-height: var(--ds-line-height-fit);
      border-radius: var(--ds-choice-border-radius);
      @include action(var(--ds-choice-border-radius));
    }
    input:checked+label {
      color: var(--ds-color-text-inverse);
      background-color: var(--ds-color-fill-inverse);
    }
  }
}