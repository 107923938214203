/* tooltip */
.dsx-Tooltip {
  --dsx-tooltip-font-color: var(--dsx-color-neutral-text-defalut);
  --dsx-tooltip-border-radius: var(--dsx-radius-small);
  --dsx-tooltip-background-color: var(--dsx-color-white);
  --dsx-tooltip-shadow: var(--dsx-shadow-1);
  --dsx-tooltip-arrow-position-top: 0;
  --dsx-tooltip-arrow-position-left: 50%;
  --dsx-tooltip-arrow-transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 0;
  padding: var(--dsx-space-2) var(--dsx-space-3);
  color: var(--dsx-tooltip-font-color);
  border-radius: var(--dsx-tooltip-border-radius);
  background-color: var(--dsx-tooltip-background-color);
  filter: drop-shadow(var(--dsx-tooltip-shadow));
  &::before {
    content: '';
    position: absolute;
    top: var(--dsx-tooltip-arrow-position-top);
    left: var(--dsx-tooltip-arrow-position-left);
    width: rem(8.5px);
    height: rem(8.5px);
    border-radius: 2.5px 0 2.5px 0;
    background-color: var(--dsx-tooltip-background-color);
    transform: translate(-50%, -50%) var(--dsx-tooltip-arrow-transform);
  }
  &[data-state='show'] {
    animation: dsx-fade-in var(--dsx-transition-fade) forwards;
  }
  &[data-state='hide'] {
    animation: dsx-fade-out var(--dsx-transition-fade) forwards;
  }
  // variant
  &--inverse {
    --dsx-tooltip-font-color: var(--dsx-color-inverse-text);
    --dsx-tooltip-background-color: var(--dsx-color-normal-fill-default);
  }
  &--primary {
    --dsx-tooltip-font-color: var(--dsx-color-inverse-text);
    --dsx-tooltip-background-color: var(--dsx-color-primary-fill-default);
  }
  // size
  &--small {
    .dsx-Tooltip-desc {
      white-space: nowrap;
    }
  }
  &--medium {
    width: rem(140px);
    padding: var(--dsx-space-3) var(--dsx-space-4);
    border-radius: var(--dsx-radius-base);
  }
  &-close {
    margin: calc(var(--dsx-space-2) * -1);
    float: right;
  }
  &-desc {
    display: block;
    font-size: var(--dsx-font-size-small);
  }
}
