
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;

    src: local('Noto Sans Regular'),
    url('./NotoSansKR-Regular.woff2') format("woff2"),
    url('./NotoSansKR-Regular.woff') format("woff");
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: local('Noto Sans Medium'),
    url('./NotoSansKR-Medium.woff2') format("woff2"),
    url('./NotoSansKR-Medium.woff') format("woff");
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;

    src: local('Noto Sans Bold'),
    url('./NotoSansKR-Bold.woff2') format("woff2"),
    url('./NotoSansKR-Bold.woff') format("woff");
}
