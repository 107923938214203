@import '../config/index.scss';

// a11y
.dsx-blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

// font color
.dsx-color-primary {
  color: var(--dsx-color-primary-text-default) !important;
}
.dsx-color-positive {
  color: var(--dsx-color-positive-text-default) !important;
}
.dsx-color-negative {
  color: var(--dsx-color-negative-text-default) !important;
}
.dsx-color-caution {
  color: var(--dsx-color-caution-text-default) !important;
}
.dsx-color-info {
  color: var(--dsx-color-info-text-default) !important;
}
.dsx-color-neutral {
  color: var(--dsx-color-neutral-text-neutral) !important;
}
.dsx-color-alternative {
  color: var(--dsx-color-neutral-text-alternative) !important;
}
.dsx-color-lime {
  color: #aef779 !important;
}

// font weight
.dsx-weight-light {
  font-weight: var(--dsx-font-weight-light) !important;
}
.dsx-weight-regular {
  font-weight: var(--dsx-font-weight-regular) !important;
}
.dsx-weight-medium {
  font-weight: var(--dsx-font-weight-medium) !important;
}
.dsx-weight-semibold {
  font-weight: var(--dsx-font-weight-semibold) !important;
}
.dsx-weight-bold {
  font-weight: var(--dsx-font-weight-bold) !important;
}

// alignment
.dsx-text-left {
  text-align: left !important;
}
.dsx-text-center {
  text-align: center !important;
}
.dsx-text-right {
  text-align: right !important;
}
.dsx-align-start {
  justify-content: flex-start !important;
}
.dsx-align-center {
  justify-content: center !important;
}
.dsx-align-end {
  justify-content: flex-end !important;
}
.dsx-align-vertical {
  flex-direction: column !important;
}
.dsx-align-fitted > * {
  flex: 1 1 0 !important;
}
.dsx-side-left {
  margin-right: auto;
}
.dsx-side-right {
  margin-left: auto;
}

// underline
.dsx-underline-always,
.dsx-underline-hover:hover {
  text-decoration: underline !important;
  text-underline-position: under !important;
}

// radius
.dsx-radius-max {
  border-radius: var(--dsx-radius-max) !important;
}

// inline
.dsx-inline-flex {
  display: inline-flex !important;
}
.dsx-inline-block {
  display: inline-block !important;
}

// spacer
.dsx-vspace {
  margin-top: 40px !important;
}
.dsx-gap-l {
  margin-left: 16px !important;
}
.dsx-gap-t {
  margin-left: 8px !important;
}

// width size
.dsx-w-auto {
  width: auto !important;
}
.dsx-w-sm {
  width: 6em !important;
}
.dsx-w-full {
  width: 100% !important;
}
.dsx-w-grow {
  flex-grow: 1 !important;
}

// 타이틀 영역 정렬
.dsx-title-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  margin: 28px 0 12px;
  &:first-child {
    margin-top: 0;
  }
  .dsx-Text {
    color: var(--dsx-color-neutral-text-neutral);
    &:where(p) {
      flex: 0 0 100%;
    }
  }
  &:has(h2) {
    margin: 40px 0 24px;
  }
}

// 아바타 + 이름 기본 정렬
.dsx-avatar-info {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
  vertical-align: top;
  &:not(:first-child) {
    margin-left: 20px;
    &::before {
      content: '';
      height: rem(14px);
      margin-right: 18px;
      border-left: 1px solid var(--ds-color-border-default);
    }
  }
}

// 콘텐츠 박스 영역
.dsx-cont-box {
  padding: var(--dsx-space-6);
  font-size: var(--dsx-font-size-body2);
  border-radius: var(--dsx-radius-large);
  background-color: #f8f9fb;
}
.dsx-pre-content {
  font-family: inherit;
  text-wrap: wrap;
}

// 구분선 스타일
.dsx-hr {
  width: 100%;
  border: solid #eaebec;
  border-width: 1px 0 0 0;
}
