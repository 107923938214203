@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(-4px) scale(.97);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
    visibility: visible;
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    transform: translateY(-4px) scale(.97);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
      opacity: 1;
  }
}