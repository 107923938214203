@import '../config/index.scss';

@font-face {
  font-family: 'Pretendard';
  font-weight: 45 920;
  font-style: normal;
  font-display: swap;
  src: url('../../font/PretendardVariable.woff2') format('woff2-variations');
}

// Works 로고 폰트
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Noto+Sans+KR:wght@400;500;700&display=swap');

// Code 폰트
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Noto+Sans+KR:wght@400;500;700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
