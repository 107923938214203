@import '../config/index.scss';

.dsx-Icon {
  display: inline-flex;
  justify-content: center;
  color: var(--dsx-icon-color, inherit);
  font-size: var(--dsx-icon-size, rem(16px));
  vertical-align: middle;
  &::before {
    content: '';
    display: inline-block;
    width: 1em;
    aspect-ratio: 1;
    background-color: currentColor;
    mask-image: var(--dsx-icon-mask-image);
    mask-size: 100%;
    mask-position: center;
    mask-repeat: no-repeat;
  }
  &--loading {
    animation: dsx-spin 2s linear infinite;
  }
}
// 아이콘 전체 가져오기
@each $key, $value in $icons {
  .dsx-Icon--#{$key} {
    --dsx-icon-mask-image: var(--dsx-icon-#{$key});
  }
}

// 아이콘을 버튼요소로 사용하는 컴포넌트
.dsx-IconButton {
  @include flex(inline-flex, center, center);
  padding: var(--dsx-space-2);
  margin: calc(var(--dsx-space-2) * -1);
  vertical-align: middle;
  // box-sizing: content-box;
  &--small {
    --dsx-icon-size: var(--dsx-scale-2);
  }
  &--medium {
    --dsx-icon-size: var(--dsx-scale-3);
  }
  &--large {
    --dsx-icon-size: var(--dsx-scale-4);
  }
  &:disabled {
    --dsx-icon-color: var(--dsx-color-interaction-fill-disabled);
  }

  // variant
  &--outline {
    margin: 0;
    border: 1px solid var(--dsx-color-neutral-border-neutral);
    border-radius: var(--dsx-radius-large);
  }
}
