@import '../config/index.scss';

.dsx-File {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  &-controls {
    display: flex;
    gap: var(--dsx-space-3);
    margin-left: auto;    
  }
  &--drag {
    --dsx-drag-border-color: transparent;
    --dsx-drag-background-color: #F8F9FB;

    flex-direction: column;
    // gap: 20px;
    gap: rem(12px);
    padding: 40px;
    text-align: center;
    border: 1px dashed var(--dsx-drag-border-color);
    border-radius: calc(var(--dsx-radius-base) * 3);
    background-color: var(--dsx-drag-background-color);

    &[data-drag=true] {
      --dsx-drag-border-color:var(--dsx-color-primary-border-default);
      --dsx-drag-background-color:var(--dsx-color-primary-fill-neutral);
    }
  }
}
.dsx-FileList {
  &-item {
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 8px 16px;
    border-radius: var(--dsx-radius-base);
    border: 1px solid var(--dsx-color-neutral-border-default);
  }
  &-link {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-right: auto;
  }
  &-upload {
    --dsx-icon-size: #{rem(20px)};
    animation: dsx-fade-in var(--dsx-transition-fade);
    .upload-success {
      color: var(--dsx-color-primary-icon-default);
    }
  }

  &--filled &-item {
    border-color: var(--dsx-color-neutral-fill-alternative);
    background-color: var(--dsx-color-neutral-fill-alternative);
  }
}
