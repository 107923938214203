@import '../config/index.scss';

.dsx-Switch {
  --dsx-switch-border-color: var(--dsx-color-interaction-border-inactive);
  --dsx-switch-background-color: transparent;
  --dsx-switch-thumb-color: var(--dsx-color-normal-icon-default);
  --dsx-switch-active-border-color: var(--dsx-color-info-fill-default);
  --dsx-switch-active-background-color: var(--dsx-color-info-fill-default);
  --dsx-switch-active-thumb-color: var(--dsx-color-inverse-icon);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  &-input {
    position: absolute;
    inset: 0;
  }
  &-label {
    display: block;
    position: relative;
    width: 40px;
    height: 20px;
    border: 1px solid var(--dsx-switch-border-color);
    background: var(--dsx-switch-background-color);
    border-radius: var(--dsx-radius-max);
    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: var(--dsx-switch-thumb-color);
      transition: transform var(--dsx-transition-base);
    }
    @include interaction();
  }
  &-input:checked + label {
    border-color: var(--dsx-switch-active-border-color);
    background-color: var(--dsx-switch-active-border-color);
  }
  &-input:checked + label::before {
    background-color: var(--dsx-switch-active-thumb-color);
    transform: translateX(calc(100% + 6px));
  }
  &-input:disabled + label {
    --dsx-switch-border-color: var(--dsx-color-interaction-border-disabled);
    --dsx-switch-thumb-color: var(--dsx-color-interaction-fill-disabled);
    --dsx-switch-active-border-color: var(--dsx-color-interaction-border-disabled);
    --dsx-switch-active-background-color: var(--dsx-color-interaction-fill-disabled);
    cursor: default;
    &::after {
      display: none;
    }
  }
}
