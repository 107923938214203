
.ds-label {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: var(--ds-form-label-font-size, var(--ds-font-size-base));
  font-weight: var(--ds-font-weight-medium);
  &.type-required::after {
    content: '필수입력항목';
    width: rem(5px);
    height: rem(5px);
    border-radius: 100%;
    background-color: var(--ds-color-negative);
    text-indent: -999px;
    overflow: hidden;
  }
}
.ds-form {
  --ds-form-label-font-size: var(--ds-font-size-base);
  .w-full {
    flex: 1;
  }
}

.ds-form-vertical {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .form-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .form-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 12px 8px;
    .symbol {
      text-align: center;
    }
  }
}

.ds-form-horizontal {
  display: table;
  width: 100%;
  margin: -16px 0;
  border-spacing: 0 16px;
  .form-item {
    display: table-row;
  }
  .form-header {
    display: table-cell;
    min-width: rem(52px);
    padding-right: 12px;
  }
  .form-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 12px 8px;
    .symbol {
      text-align: center;
    }
  }
}