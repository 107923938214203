@import '../config/index.scss';

/* Radio */
.dsx-Radio {
  --dsx-radio-size: #{rem(16px)};
  --dsx-radio-margin: 2px;
  --dsx-radio-border-color: var(--dsx-color-neutral-icon-neutral);
  --dsx-radio-checked-border-color: var(--dsx-color-primary-fill-default);
  --dsx-radio-checked-background-color: var(--dsx-color-primary-fill-default);
  display: inline-flex;
  align-items: flex-start;
  vertical-align: middle;
  &-input {
    flex: 0 0 auto;
    position: relative;
    width: var(--dsx-radio-size);
    height: var(--dsx-radio-size);
    margin: var(--dsx-radio-margin) 0;
    border: 1px solid var(--dsx-radio-border-color);
    border-radius: 100%;
    appearance: none;
    cursor: pointer;
    @include interaction(-1px);
    &:checked {
      border-color: var(--dsx-radio-checked-border-color);
      background: var(--dsx-radio-checked-background-color);
      box-shadow: inset 0 0 0 2px var(--dsx-color-neutral-surface-default);
      background-clip: content-box;
      &:checked:disabled {
        background-color: var(--dsx-color-interaction-fill-disabled);
      }
    }
    &:disabled {
      border-color: var(--dsx-color-interaction-border-disabled);
      cursor: default;
    }
  }
  &-label {
    padding: 0 0 0 8px;
    color: var(--dsx-color-neutral-text-default);
  }
  &:has(:disabled) &-label {
    color: var(--dsx-color-interaction-text-disabled);
    cursor: default;
  }
  &.is-invalid {
    --dsx-radio-border-color: var(--dsx-color-negative-fill-default);
    --dsx-radio-checked-border-color: var(--dsx-color-negative-fill-default);
    --dsx-radio-checked-background-color: var(--dsx-color-negative-fill-default);
  }

  // SIZE
  &--large {
    --dsx-radio-size: #{rem(20px)};
    --dsx-radio-margin: 1px;
    font-size: var(--dsx-font-size-body2);
  }
  &--large &-input:checked {
    box-shadow: inset 0 0 0 3px var(--dsx-color-neutral-surface-default);
  }
}

.dsx-RadioGroup {
  @include flex($v: center, $w: wrap);
  gap: 8px;
  .dsx-Radio {
    margin-right: 8px;
  }
  .dsx-Radio--large {
    margin-right: 16px;
  }
}
