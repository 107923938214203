.ds-tabs {
  --ds-tabs-selected-color: var(--ds-color-primary);
  position: relative;
  .tab-list {
    display: flex;
    align-items: center;
    box-shadow: inset 0px -1px 0px var(--ds-color-border-subtler);
    .tab-item {
      flex: 1 1 0;
    }
    .tab {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(37px);
      color: var(--ds-color-text-subtle);
      font-weight: var(--ds-font-weight-medium);
      &[aria-selected="true"] {
        color: var(--ds-tabs-selected-color);
        box-shadow: inset 0px -2px 0px var(--ds-tabs-selected-color);
      }
    }
  }
  .tab-panel {
    display: none;
    &.is-active {
      display: block;
    }
  }
}