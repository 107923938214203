.ds-avatar {
  --ds-avatar-size: #{rem(32px)};
  @include flex(flex, center, center);
  width: var(--ds-avatar-size);
  aspect-ratio: 1;
  border-radius: 100%;
  overflow: hidden;
  background: var(--ds-icon-profile-fill) 50% 50% no-repeat;
  background-size: 100%;
  img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}