// 사이드바

// 상단 버튼 영역
.chat-set {
  padding: var(--ds-chat-aside-spacer) 0;
  border-bottom: 1px solid var(--ds-color-border-subtle);
  background-color: var(--ds-chat-aside-background-color);
  z-index: 1;
}
// 상단 버튼
.chat-button {
  position: relative;
  @include flex(flex, flex-start, center);
  gap: 12px;
  width: 100%;
  padding: 12px 20px;
  border: 0;
  border-radius: var(--ds-border-radius-lg);
  background: none;
  appearance: none;
  @include action(var(--ds-border-radius-lg));
  .ds-icon {
    width: rem(24px);
  }
}

// 채팅 목록
.chat-nav {
  overflow-y: auto;
  margin: 0 calc(var(--ds-chat-aside-spacer) * -1);
  padding: var(--ds-chat-aside-spacer);
  .nav-section {
    margin-top: var(--ds-chat-aside-spacer);
  }
}
.chat-date {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 24px;
  color: var(--ds-color-text-subtle);
  font-size: var(--ds-font-size-sm);
  cursor: pointer;
}
.chat-list {
  &-item {
    position: relative;
    border-radius: var(--ds-border-radius-lg);
    &:has(.chat-link:hover, .chat-option:hover) {
      background-color: var(--ds-color-action-hover);
    }
    &:has(.chat-link:focus-visible, .chat-option > :focus-visible) {
      background-color: var(--ds-color-action-focus);
    }
    &:has(.chat-link:hover, .chat-option:hover, .chat-link:focus-visible, .chat-option > :focus-visible) {
      .chat-link {
        padding-right: rem(72px);
      }
      .chat-link::after {
        display: none;
      }
      .chat-option {
        z-index: 1;
      }
    }
    &:has(.chat-edit-name) {
      .chat-link .name {
        opacity: 0;
      }
      .chat-option {
        z-index: -2;
      }
    }
  }
  // 목록 링크
  .chat-link {
    display: block;
    padding: 12px 20px;
    padding-right: rem(56px);
    border-radius: var(--ds-border-radius-lg);
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 20px;
      width: rem(24px);
      height: rem(24px);
      margin-top: rem(-12px);
      -webkit-mask: var(--ds-icon-more) 50% 50% no-repeat;
      -webkit-mask-size: 100%;
      background-color: var(--ds-color-normal);
    }
    &:active {
      background-color: var(--ds-color-action-active);
    }
    .name {
      display: block;
      @include ellipsis(1);
    }
    .desc {
      display: block;
      color: var(--ds-color-text-subtle);
      font-size: var(--ds-font-size-sm);
      @include ellipsis(1);
    }
  }
  // 수정/삭제 버튼
  .chat-option {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -12px;
    color: var(--ds-color-normal);
    z-index: -2;
    &:has(:focus-visible) {
      z-index: 1;
    }
  }
  // 이름 수정
  .chat-edit-name {
    position: absolute;
    inset: 0;
    padding: 12px rem(52px) 12px 20px;
    .edit-input {
      width: 100%;
      margin: 0;
      padding: 0;
      font-weight: var(--ds-font-weight-semibold);
      border: 0;
      background: none;
    }
  }
}
