@import '../config/index.scss';

.dsx-Avatar {
  --dsx-icon-size: 2em;
  position: relative;
  @include flex(flex, center, center);
  width: var(--dsx-avatar-size);
  aspect-ratio: 1;
  color: var(--dsx-color-normal-text-neutral);
  font-size: var(--dsx-avatar-font-size);
  font-weight: var(--dsx-avatar-font-weight);
  line-height: var(--dsx-line-height-fit);
  border-radius: 100%;
  // background: var(--ds-icon-profile-fill) 50% 50% no-repeat;
  background-size: 100%;
  // background-color: var(--dsx-color-neutral-fill-default);
  outline-offset: 3px;
  &.fit-height {
    height: var(--dsx-avatar-size);
  }
  &-image {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    border-radius: inherit;
    box-shadow: inset 0 0 0 1px var(--dsx-color-neutral-border-alternative);
    max-width: -webkit-fill-available;
    max-height: -webkit-fill-available;
  }
  &:where(button) {
    @include interaction();
    &:disabled {
      filter: grayscale(1) opacity(0.5);
    }
  }
  &.is-invalid {
    color: var(--dsx-color-negative-text-default);
    background-color: var(--dsx-color-negative-fill-neutral);
    border-color: var(--dsx-color-negative-border-default);
  }

  // variant
  &--ring {
    --dsx-avatar-ring-color: var(--dsx-color-normal-border-neutral);
    margin: 6px;
    &::before {
      content: '';
      position: absolute;
      inset: -6px;
      border: 3px solid var(--dsx-avatar-ring-color);
      border-radius: inherit;
    }
    &[data-accent='primary'] {
      --dsx-avatar-ring-color: var(--dsx-color-primary-border-neutral);
    }
    &[data-accent='positive'] {
      --dsx-avatar-ring-color: var(--dsx-color-positive-border-neutral);
    }
    &[data-accent='negative'] {
      --dsx-avatar-ring-color: var(--dsx-color-negative-border-neutral);
    }
    &[data-accent='caution'] {
      --dsx-avatar-ring-color: var(--dsx-color-caution-border-neutral);
    }
    &[data-accent='info'] {
      --dsx-avatar-ring-color: var(--dsx-color-info-border-neutral);
    }
  }
  &--empty {
    padding-top: 8px;
    font-size: var(--dsx-font-size-caption1);
    font-weight: var(--dsx-font-weight-regular);
    line-height: 1.3;
    border: 1px dashed var(--dsx-color-neutral-border-default);
    background-color: var(--dsx-color-primary-surface-default);
  }
  &--user:not(:has(img)) {
    background: var(--dsx-icon-profile);
    background-size: 100%;
    filter: invert(1) opacity(0.8);
  }

  // size
  &--size1 {
    --dsx-avatar-size: var(--dsx-scale-4);
    --dsx-avatar-font-size: var(--dsx-font-size-caption2);
    --dsx-avatar-font-weight: var(--dsx-font-weight-semibold);
  }
  &--size2 {
    --dsx-avatar-size: var(--dsx-scale-5);
    --dsx-avatar-font-size: var(--dsx-font-size-label2);
    --dsx-avatar-font-weight: var(--dsx-font-weight-semibold);
  }
  &--size3 {
    --dsx-avatar-size: var(--dsx-scale-6);
    --dsx-avatar-font-size: var(--dsx-font-size-body2);
    --dsx-avatar-font-weight: var(--dsx-font-weight-semibold);
  }
  &--size4 {
    --dsx-avatar-size: var(--dsx-scale-7);
    --dsx-avatar-font-size: var(--dsx-font-size-body1);
    --dsx-avatar-font-weight: var(--dsx-font-weight-semibold);
  }
  &--size5 {
    --dsx-avatar-size: var(--dsx-scale-8);
    --dsx-avatar-font-size: var(--dsx-font-size-title2);
    --dsx-avatar-font-weight: var(--dsx-font-weight-semibold);
  }
  &--size6 {
    --dsx-avatar-size: var(--dsx-scale-9);
    --dsx-avatar-font-size: var(--dsx-font-size-title1);
    --dsx-avatar-font-weight: var(--dsx-font-weight-semibold);
  }
  &--size7 {
    --dsx-avatar-size: var(--dsx-scale-10);
    --dsx-avatar-font-size: var(--dsx-font-size-heading3);
    --dsx-avatar-font-weight: var(--dsx-font-weight-bold);
  }
  &--size8 {
    --dsx-avatar-size: #{rem(96px)};
    --dsx-avatar-font-size: var(--dsx-font-size-heading2);
    --dsx-avatar-font-weight: var(--dsx-font-weight-bold);
  }
  &--size9 {
    --dsx-avatar-size: #{rem(120px)};
    --dsx-avatar-font-size: var(--dsx-font-size-heading1);
    --dsx-avatar-font-weight: var(--dsx-font-weight-bold);
  }
}

// 아바타 정렬 추가 예정
