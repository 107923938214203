@import '../config/index.scss';

:root,
.theme-light {
  // Colors
  @each $attr, $value in $colors {
    --dsx-color-#{$attr}: #{$value};
  }

  // Borders
  @each $attr, $value in $borders {
    --dsx-#{$attr}: #{$value};
  }

  // Fonts
  @each $attr, $value in $fonts {
    --dsx-#{$attr}: #{$value};
  }

  // Spaces
  @each $attr, $value in $spaces {
    --dsx-#{$attr}: #{$value};
  }

  // Shadows
  @each $attr, $value in $shadows {
    --dsx-#{$attr}: #{$value};
  }

  //Transitions
  @each $attr, $value in $transitions {
    --dsx-#{$attr}: #{$value};
  }

  // Icons
  @each $attr, $value in $icons {
    --dsx-icon-#{$attr}: #{$value};
  }
}

@if $config-dark-mode {
  @include color-mode(dark, true) {
    color-scheme: dark;
    // Colors
    @each $attr, $value in $colors-dark {
      --dsx-color-#{$attr}: #{$value};
    }
  }
}

@include mobile {
  :root {
    // Fonts
    @each $attr, $value in $fonts-mobile {
      --dsx-#{$attr}: #{$value};
    }
  }
}
