@use 'sass:map';

// Breakpoint : desktop first
// 반응형이 아닌 경우 `$min-width`값을 조정할 수 있다.
$min-width: 1280px;

$mobile: 768px;
$tablet: 1024px;
$laptop: 1279px;
$desktop: $laptop + 1; // desktop 사이즈에서만 필요한 스타일인 경우 활용

@mixin desktop {
  @media screen and (min-width:#{$desktop}) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width:#{$laptop}) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width:#{$tablet}) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width:#{$mobile}) {
    @content;
  }
}
