@import '../config/index.scss';

/* Textarea */
.dsx-Textarea {
  --dsx-textarea-padding: 12px 16px;
  --dsx-textarea-font-size: var(--dsx-font-size-base);
  --dsx-textarea-border-color: var(--dsx-color-neutral-border-alternative);
  --dsx-textarea-border-radius: var(--dsx-radius-base);
  --dsx-textarea-background-color: var(--dsx-color-neutral-surface-default);
  --dsx-textarea-focus-border-color: var(--dsx-color-primary-border-default);
  position: relative;
  @include flex(flex, flex-start, flex-start);
  width: 100%;
  color: var(--dsx-color-neutral-text-default);
  border: 1px solid var(--dsx-textarea-border-color);
  border-radius: var(--dsx-textarea-border-radius);
  background-color: var(--dsx-textarea-background-color);
  transition: background-color var(--dsx-transition-base);
  cursor: text;

  // TEXT FIELD
  &-field {
    position: relative;
    appearance: none;
    width: 100%;
    height: 100%;
    padding: var(--dsx-textarea-padding);
    font-size: var(--dsx-textarea-font-size);
    background: none;
    outline: 0;
    @include scrollbar();
    &::placeholder {
      color: var(--dsx-color-interaction-text-inactive);
      font-weight: var(--dsx-font-weight-regular);
      white-space: pre-wrap;
    }
    &[type='button'] {
      cursor: text;
    }
  }

  &:active,
  &:has(:focus) {
    border-color: var(--dsx-textarea-focus-border-color);
  }
  &:has(:disabled) {
    color: var(--dsx-color-interaction-text-disabled);
    border-color: var(--dsx-color-interaction-border-disabled);
    background-color: var(--dsx-color-interaction-fill-disabled);
    &::after {
      display: none;
    }
  }
  &:has([readonly]) {
    background-color: var(--dsx-color-interaction-fill-disabled);
  }
  &.is-invalid {
    border-color: var(--dsx-color-negative-border-default) !important;
  }
  // VARIANT
  &--filled {
    --dsx-textarea-border-color: transparent;
    --dsx-textarea-background-color: #f8f9fb;
  }

  // SIZE
  &--large {
    --dsx-textarea-padding: 12px 16px;
  }
  &--xlarge {
    --dsx-textarea-padding: var(--dsx-space-5);
    --dsx-textarea-border-radius: var(--dsx-radius-xlarge);
    --dsx-textarea-font-size: var(--dsx-font-size-body2);
  }
}
