.ds-popover {
  --ds-popover-border-color: var(--ds-color-border-subtler);
  --ds-popover-border-radius: var(--ds-border-radius-lg);
  --ds-popover-background-color: var(--ds-color-surface-overlay);
  --ds-popover-box-shadow: var(--ds-box-shadow-base);
  position: absolute;
  padding: 23px;
  color: var(--ds-color-text-default);
  border: 1px solid var(--ds-popover-border-color);
  border-radius: var(--ds-popover-border-radius);
  background-color: var(--ds-popover-background-color);
  box-shadow: var(--ds-popover-box-shadow);
  z-index: $z-index-popover;
  visibility: hidden;
  &.is-show {
    visibility: visible;
  }
  &[data-state="show"] {
    animation: fadeInUp var(--ds-transition-popover) forwards;
    visibility: visible;
  }
  &[data-state="hide"] {
    animation: fadeOutDown var(--ds-transition-popover) forwards;
    visibility: hidden;
  }
  &::-webkit-scrollbar-button {
    height: 4px;
  }
  .ds-form-vertical {
    gap: 8px;
  }
}