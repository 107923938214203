/*
 * Nanum Gothic (Korean) http://www.google.com/fonts/earlyaccess
 */
@font-face {
    font-family: 'Nanum Gothic';
    font-style: normal;
    font-weight: 400;

    src: local('Nanum-Gothic'),
    url('./NanumGothic.woff2') format("woff2"),
    url('./NanumGothic.woff') format("woff");
}
@font-face {
    font-family: 'Nanum Gothic';
    font-style: normal;
    font-weight: 600;

    src: local('Nanum-Gothic-Bold'),
    url('./NanumGothicBold.woff2') format("woff2"),
    url('./NanumGothicBold.woff') format("woff");
}
