@import '../config/index.scss';

.dsx-Popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  &[data-state='show'] {
    animation:
      dsx-slide-from-bottom var(--dsx-transition-popover) forwards,
      dsx-fade-in var(--dsx-transition-popover) forwards;
  }
  &[data-state='hide'] {
    animation:
      dsx-slide-to-bottom var(--dsx-transition-popover) forwards,
      dsx-fade-out var(--dsx-transition-popover) forwards;
  }
  &-pseudoElement {
    position: fixed;
    top: 0;
    left: 0;
  }
  &-inner {
    width: fit-content;
    border: 1px solid var(--dsx-color-neutral-border-alternative);
    border-radius: var(--dsx-radius-xlarge);
    background-color: var(--dsx-color-white);
    box-shadow: var(--dsx-shadow-4);
  }
}
