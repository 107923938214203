// 채팅 레이아웃
html,
body {
  height: 100%;
}
.chat-view {
  --ds-chat-aside-width: #{rem(260px)};
  --ds-chat-aside-border-color: var(--ds-color-border-subtle);
  --ds-chat-aside-background-color: var(--ds-color-surface-paper);
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 100%;
  height: 100%;
  overflow: hidden;
}
.chat-main {
  position: relative;
  overflow-y: auto;
}
.chat-aside {
  --ds-chat-aside-spacer: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: var(--ds-chat-aside-width);
  padding: 0 var(--ds-chat-aside-spacer);
  border-right: 1px solid var(--ds-chat-aside-border-color);
  background-color: var(--ds-chat-aside-background-color);
  transition: margin 0.35s ease-in-out;
  &.is-close {
    margin-left: calc(var(--ds-chat-aside-width) * -1);
  }
  .chat-aside-collapse {
    position: absolute;
    top: 50%;
    right: rem(-28px);
    transform: translateY(-50%);
    z-index: 10;
  }
}
// 채팅기록 확장축소 버튼
.chat-aside-collapse {
  .collapse-control {
    .move-arrow {
      @include flex(flex, center, center);
      width: rem(24px);
      height: rem(48px);
      flex-direction: column;
      color: var(--ds-color-normal-fill-default);
      opacity: 0.22;
      &::before,
      &::after {
        content: '';
        width: rem(3px);
        height: rem(12px);
        margin: rem(-1.5px) 0;
        border-radius: var(--ds-border-radius-max);
        background-color: currentColor;
        transition: all var(--ds-transition-base);
      }
      &:hover,
      &:focus {
        opacity: 1;
        &::before {
          transform: rotate(30deg);
          border-radius: var(--ds-border-radius-max) var(--ds-border-radius-max) 0 0;
        }
        &::after {
          transform: rotate(-30deg);
          border-radius: 0 0 var(--ds-border-radius-max) var(--ds-border-radius-max);
        }
      }
    }
  }
  &.is-close {
    .collapse-control {
      .move-arrow {
        &::before {
          transform: rotate(-30deg);
          border-radius: var(--ds-border-radius-max) var(--ds-border-radius-max) 0 0;
        }
        &::after {
          transform: rotate(30deg);
          border-radius: 0 0 var(--ds-border-radius-max) var(--ds-border-radius-max);
        }
      }
    }
  }
}
