@import '../config/index.scss';

/* Text */
.dsx-Text {
  margin: 0;
  .dsx-Icon,
  .dsx-Badge {
    margin-top: -2px;
    vertical-align: middle;
    &:where(:first-child) {
      margin-right: 4px;
    }
  }
  // size
  &--body1 {
    font-size: var(--dsx-font-size-body1) !important;
  }
  &--body2 {
    font-size: var(--dsx-font-size-body2) !important;
  }
  &--body3 {
    font-size: var(--dsx-font-size-body3) !important;
  }
  &--label1 {
    font-size: var(--dsx-font-size-label1) !important;
  }
  &--label2 {
    font-size: var(--dsx-font-size-label2) !important;
  }
  &--caption1 {
    font-size: var(--dsx-font-size-caption1) !important;
  }
  &--caption2 {
    font-size: var(--dsx-font-size-caption2) !important;
  }

  // variant
  &--error {
    --dsx-icon-color: var(--dsx-color-negative-icon-default);
    margin-top: 4px;
    color: var(--dsx-color-negative-text-default);
    font-size: var(--dsx-font-size-caption1);
    &::before {
      content: '';
      display: inline-block;
      width: rem(16px);
      height: rem(16px);
      margin: -2px 4px 0 0;
      vertical-align: middle;
      -webkit-mask: var(--dsx-icon-errorFill) 50% 50% no-repeat;
      -webkit-mask-size: 100%;
      background-color: var(--dsx-icon-color);
    }
  }
  &--length {
    margin-top: 4px;
    color: var(--dsx-color-neutral-text-alternative);
    font-size: var(--dsx-font-size-caption1);
    text-align: right;
  }
  &:where(pre) {
    max-width: 100%;
    font: inherit;
    white-space: pre-line;
  }
}
