/* Reset */

// 브라우저 간의 일관성을 유지하기 위해 기본 스타일을 재설정합니다.
// 여기서 모든 스타일의 속성을 초기화하지는 않습니다.
// 스타일의 완전한 제거는 커스텀 요소에 따라 CSS 클래스를 통해 설정합니다.

// 1. 모든 요소의 크기가 `padding` 또는 `border`에 영향을 받지 않도록 조정
*,
*:before,
*:after {
  box-sizing: border-box; // 1
}

// 1. `rem`단위 기준 폰트 사이즈 설정
// 2. 부드러운 스크롤 효과
// 3. Webkit 브라우저의 폰트 렌더링 품질 개선
// 4. iOS 가로 모드 글꼴 크기 조정 방지
html {
  font-size: $html-font-size; // 1
  scroll-behavior: smooth; // 2
  -webkit-font-smoothing: antialiased; // 3
  -webkit-text-size-adjust: 100%; // 4
}

// 문서의 기본 설정
body {
  min-width: $min-width;
  margin: 0;
  color: $body-color;
  font-family: $body-font-family;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: $body-line-height;
  @if $body-letter-spacing != null {
    letter-spacing: $body-letter-spacing;
  }
  background-color: $body-background;
  word-break: keep-all;
  overflow-wrap: break-word;
}

// HTML5 태그에 대한 IE 호환성 설정
header,
footer,
main,
section,
article,
nav,
aside {
  display: block;
}

// 제목 태그의 기본 설정
h1,
h2,
h3,
h4,
h5,
h6,
[role="heading"] {
  color: $heading-color;
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
  &:first-child {
    margin-top: 0;
  }
}

// 코드 태그의 기본 설정
pre,
code,
kbd,
samp {
  font-family: $code-font-family;
  font-size: $code-font-size;
}
// 클래스가 없는 링크 태그의 기본 설정 
a:not([class]) {
  color: $link-color;
  text-decoration: $link-decoration;
  text-underline-position: under;
  background-color: transparent;
}
// 링크 태그의 기본 설정
a {
  color: inherit;
  text-decoration: none;
}

// 이미지의 원본사이즈까지 확장되도록 설정
img {
  border: 0;
  max-width: 100%;
  vertical-align: top;
}

// fieldset의 기본 설정
fieldset {
  margin: 0;
  padding: 1em;
}
legend {
  color: inherit;
  display: table;
  max-width: 100%;
}

// iframe의 border 제거
iframe {
  border: 0;
}

// textarea의 사이즈 조정 방지
textarea {
  resize: none;
}

// table의 스타일 초기화
table {
  width: 100%;
  border-collapse: collapse;
  empty-cells: show;
  border-spacing: 0;
}
caption {
  caption-side: bottom;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}
th {
  font-weight: $th-font-weight;
}

// text 요소의 기본 설정

p {
  margin: $paragraph-margin-y 0;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul:not([class]), ol:not([class]) {
  list-style: revert;
  margin: revert;
  padding: revert;
}

em,
address,
i {
  font-style: normal;
}

u {
  text-underline-position: under;
}

sub {
  line-height: 1;
  vertical-align: bottom;
}

sup {
  line-height: 1;
  vertical-align: top;
}

em {
  font-weight: $em-font-weight;
}

strong, b {
  font-weight: $strong-font-weight;
}

// action 요소의 기본 설정

label {
  vertical-align: middle;
  cursor: pointer;
}

summary {
  display: list-item;
  cursor: pointer;
}

button {
  position: relative;
  color: inherit;
  font: inherit;
  line-height: $button-line-height;
  cursor: pointer;
}

button:disabled,
button[disabled] {
  cursor: default;
}

input,
textarea,
select {
  color: inherit;
  font: inherit;
  box-shadow: none;
  vertical-align: middle;
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s 0s;
    -webkit-text-fill-color: black !important;
  }
}

input::-ms-clear {
  display: none;
}

// 1. 터치 요소의 더블탭 확대 방지
// 2. iOS 터치 색상을 투명으로 설정
a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation; // 1
  touch-action: manipulation; // 1
  -webkit-tap-highlight-color: transparent; // 2
}

// `tabindex="0"` 요소의 cursor 스타일 설정
[tabindex="0"] {
  cursor: pointer;
}