// 접근성 글자 숨기기
@mixin text-hidden {
  text-indent: -9999em;
  overflow: hidden;
}

// 접근성 영역 숨기기
@mixin blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

// 말줄임
@mixin ellipsis($line, $line-height: $line-height-base) {
  @if ($line == 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    max-height: $line-height-base * $line + em; //ie 크로스브라우징
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
  }
}

// 그라디언트 생성
@mixin horizontal-gradient($from, $to, $stop: 100%) {
  // fallback/image non-cover color
  background-color: $from;
  // Firefox 3.6+
  background-image: -moz-linear-gradient(left, $from 0%, $to $stop);
  // Safari 4+, Chrome 1+
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop($stop, $to));
  // Safari 5.1+, Chrome 10+
  background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);
  // Opera 11.10+
  background-image: -o-linear-gradient(left, $from 0%, $to $stop);
  // IE10+
  background: -ms-linear-gradient(left, $from 0%, $to $stop);
  // Standard
  background: linear-gradient(to right, $from 0%, $to $stop);
  // IE6-9
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=1);
}

// Flex
@mixin flex($display: flex, $h: null, $v: null, $d: null, $w: null) {
  display: $display;
  @if $d != null {
    flex-direction: $d;
  }
  @if $h != null {
    justify-content: $h;
  }
  @if $v != null {
    align-items: $v;
  }
  @if $w != null {
    flex-wrap: $w;
  }
  @content;
}

// Color mode
@mixin color-mode($mode: light, $root: false) {
  @if $color-mode-type == 'media-query' {
    @if $root == true {
      @media (prefers-color-scheme: $mode) {
        :root {
          @content;
        }
      }
    } @else {
      @media (prefers-color-scheme: $mode) {
        @content;
      }
    }
  } @else {
    .theme-#{$mode} {
      @content;
    }
  }
}

// scrollbar
@mixin scrollbar() {
  &::-webkit-scrollbar {
    height: 0.5rem;
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: var(--dsx-radius-max);
    border: 2px solid transparent;
    background-clip: padding-box;
    background-color: var(--dsx-color-neutral-fill-strong);
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: var(--dsx-radius-max);
  }
}

// interaction
// 버튼 또는 링크의 hover, focus, active 상태값 스타일 설정
@mixin interaction($inset: 0, $type: null, $bg: var(--dsx-color-neutral-text-default)) {
  &:not(:disabled)::after {
    content: '';
    position: absolute;
    inset: $inset;
    border-radius: inherit;
    opacity: 0;
    visibility: hidden;
    background-color: $bg;
    transition: var(--dsx-transition-base);
  }

  @if $type == 'form' {
    &:not(:disabled):hover::after {
      visibility: visible;
      opacity: 0.05;
    }
    &:not(:disabled):focus::after {
      visibility: visible;
      opacity: 0.05;
    }
  } @else {
    &:not(:disabled):hover::after {
      visibility: visible;
      opacity: 0.05;
    }
    &:not(:disabled):focus-visible::after {
      visibility: visible;
      opacity: 0.08;
    }
    &:not(:disabled):active::after {
      visibility: visible;
      opacity: 0.12;
    }
  }
}

/* 가상 엘리먼트(::before, ::after) */
@mixin el-pseudo($w: 1px, $h: 1px) {
  width: $w;
  height: $h;
  display: block;
  content: '';
}

/* Position Layout */
@mixin layout($p: absolute, $t: auto, $l: auto, $b: auto, $r: auto, $i: null) {
  position: $p;
  top: $t;
  left: $l;
  bottom: $b;
  right: $r;
  @if $i != null {
    z-index: $i;
  }
}

/* Skeleton */
@mixin skeleton {
  background: linear-gradient(120deg, #f4f4f5 30%, #fcfcfc 39.5%, #fcfcfc 40%, #f4f4f5 48%);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: dsx-skeleton-loading 1.3s infinite;
}
