@import '../config/index.scss';

.dsx-Badge {
  --dsx-badge-height: var(--dsx-scale-3);
  --dsx-badge-padding: 2px 6px;
  --dsx-badge-font-color: var(--dsx-color-normal-icon-default);
  --dsx-badge-font-size: #{rem(11px)};
  --dsx-badge-font-weight: var(--dsx-font-weight-medium);
  --dsx-badge-border-radius: var(--dsx-radius-base);
  --dsx-badge-border-color: var(--dsx-color-normal-border-default);
  --dsx-badge-background-color: var(--dsx-color-normal-fill-neutral);
  --dsx-icon-size: #{rem(16px)};
  @include flex(inline-flex, center, center);
  gap: calc(4px + var(--dsx-space-1));
  min-width: var(--dsx-badge-height);
  height: var(--dsx-badge-height);
  padding: var(--dsx-badge-padding);
  color: var(--dsx-badge-font-color);
  font-size: var(--dsx-badge-font-size);
  font-weight: var(--dsx-badge-font-weight);
  text-align: center;
  line-height: var(--dsx-line-height-fit);
  border-radius: var(--dsx-badge-border-radius);
  background-color: var(--dsx-badge-background-color);
  box-shadow: inset 0 0 0 1px var(--dsx-badge-border-color);
  .dsx-Icon {
    margin: 0 -4px;
  }

  // variant
  &--tint {
    --dsx-badge-border-color: transparent !important;
  }
  &--filled {
    --dsx-badge-filled-color: var(--dsx-color-normal-fill-default);
    --dsx-badge-font-color: var(--dsx-color-inverse-text) !important;
    --dsx-badge-border-color: transparent !important;
    --dsx-badge-background-color: var(--dsx-badge-filled-color) !important;
  }
  &--ghost {
    --dsx-badge-border-color: transparent !important;
    --dsx-badge-background-color: transparent !important;
  }
  &--ring {
    --dsx-badge-filled-color: var(--dsx-color-normal-fill-default);
    --dsx-badge-padding: 0 !important;
    --dsx-badge-font-color: var(--dsx-color-inverse-text) !important;
    --dsx-badge-border-color: transparent !important;
    --dsx-badge-background-color: var(--dsx-badge-filled-color) !important;
    --dsx-badge-border-radius: var(--dsx-radius-max);
    outline: 2px solid var(--dsx-color-neutral-surface-default);
    &.dsx-Badge--large {
      --dsx-icon-size: var(--dsx-scale-3);
    }
  }

  // size
  &--dot {
    --dsx-badge-height: #{rem(6px)};
    --dsx-badge-padding: 0 !important;
    .dsx-Icon {
      display: none;
    }
  }
  &--xsmall {
    --dsx-badge-height: #{rem(12px)};
    --dsx-badge-padding: 0;
    --dsx-icon-size: #{rem(12px)};
  }
  &--small {
    --dsx-badge-height: #{rem(16px)};
    --dsx-badge-padding: 2px 4px;
    --dsx-icon-size: #{rem(12px)};
  }
  &--large {
    --dsx-badge-height: #{rem(26px)};
    --dsx-badge-padding: 2px 8px;
    --dsx-badge-font-size: #{rem(13px)};
    --dsx-icon-size: #{rem(16px)};
  }
  &--xlarge {
    --dsx-badge-height: #{rem(28px)};
    --dsx-badge-padding: 2px 8px;
    --dsx-badge-font-size: var(--dsx-font-size-large);
    --dsx-icon-size: var(--dsx-scale-4);
  }
  &--xxlarge {
    --dsx-badge-height: #{rem(30px)};
    --dsx-badge-padding: 2px 8px;
    --dsx-badge-font-size: var(--dsx-font-size-large);
    --dsx-icon-size: var(--dsx-scale-4);
  }

  // color
  &[data-accent='primary'] {
    --dsx-badge-filled-color: var(--dsx-color-primary-icon-default);
    --dsx-badge-font-color: var(--dsx-color-primary-text-default);
    --dsx-badge-border-color: var(--dsx-color-primary-border-neutral);
    --dsx-badge-background-color: #f5f7ff;
  }
  &[data-accent='positive'] {
    --dsx-badge-filled-color: var(--dsx-color-positive-fill-default);
    --dsx-badge-font-color: var(--dsx-color-positive-icon-default);
    --dsx-badge-border-color: var(--dsx-color-positive-border-neutral);
    --dsx-badge-background-color: var(--dsx-color-positive-fill-neutral);
  }
  &[data-accent='negative'] {
    --dsx-badge-filled-color: var(--dsx-color-negative-fill-default);
    --dsx-badge-font-color: var(--dsx-color-negative-icon-default);
    --dsx-badge-border-color: var(--dsx-color-negative-border-neutral);
    --dsx-badge-background-color: var(--dsx-color-negative-fill-neutral);
  }
  &[data-accent='caution'] {
    --dsx-badge-filled-color: var(--dsx-color-caution-fill-default);
    --dsx-badge-font-color: var(--dsx-color-caution-icon-default);
    --dsx-badge-border-color: var(--dsx-color-caution-border-neutral);
    --dsx-badge-background-color: var(--dsx-color-caution-fill-neutral);
  }
  &[data-accent='info'] {
    --dsx-badge-filled-color: var(--dsx-color-info-fill-default);
    --dsx-badge-font-color: var(--dsx-color-info-icon-default);
    --dsx-badge-border-color: var(--dsx-color-info-border-neutral);
    --dsx-badge-background-color: var(--dsx-color-info-fill-neutral);
  }
  &[data-accent='violet'] {
    --dsx-badge-filled-color: var(--dsx-color-violet-fill-default);
    --dsx-badge-font-color: var(--dsx-color-violet-icon-default);
    --dsx-badge-font-weight: var(--dsx-font-weight-semibold);
    --dsx-badge-border-color: var(--dsx-color-violet-border-neutral);
    --dsx-badge-background-color: var(--dsx-color-violet-fill-neutral);
  }

  // position
  &[data-position='topStart'] {
    position: absolute;
    inset: 0 auto auto 0;
  }
  &[data-position='topEnd'] {
    position: absolute;
    inset: 0 0 auto auto;
  }
  &[data-position='bottomStart'] {
    position: absolute;
    inset: auto auto 0 0;
  }
  &[data-position='bottomEnd'] {
    position: absolute;
    inset: auto 0 0 auto;
  }
}

.badge-group {
  display: flex;
  align-items: center;
  gap: 6px;
}
