 
// 접근성 글자 숨기기
@mixin text-hidden {
  text-indent: -9999em;
  overflow: hidden;
}

// 접근성 영역 숨기기
@mixin blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

// 말줄임
@mixin ellipsis($line, $line-height : $line-height-base) {
    @if ($line == 1) { 
        overflow: hidden; 
        text-overflow: ellipsis; 
        white-space: nowrap; 
    } @else { 
        display: block; //-webkit-box
        max-height:$line-height-base * $line + em; //ie 크로스브라우징
        overflow: hidden; 
        text-overflow: ellipsis; 
        -webkit-box-orient: vertical; 
        -webkit-line-clamp: $line; 
        word-break: normal;
    } 
}

// 그라디언트 생성
@mixin horizontal-gradient($from, $to, $stop: 100%) {
  // fallback/image non-cover color
  background-color: $from;
  // Firefox 3.6+
  background-image: -moz-linear-gradient(left, $from 0%, $to $stop);
  // Safari 4+, Chrome 1+
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(0%, $from),
      color-stop($stop, $to));
  // Safari 5.1+, Chrome 10+
  background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);
  // Opera 11.10+
  background-image: -o-linear-gradient(left, $from 0%, $to $stop);
  // IE10+
  background: -ms-linear-gradient(left, $from 0%, $to $stop);
  // Standard
  background: linear-gradient(to right, $from 0%, $to $stop);
  // IE6-9
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=1);
}

// Flex
@mixin flex($display: flex, $h: null, $v: null, $direction: null) {
	display: $display;
	@if $direction != null {
		flex-direction: $direction;
	}
	@if $h != null {
		justify-content: $h;
	}
	@if $v != null {
		align-items: $v;
	}
	@content;
}

// Color mode
@mixin color-mode($mode: light, $root: false) {
  @if $color-mode-type == "media-query" {
    @if $root == true {
      @media (prefers-color-scheme: $mode) {
        :root {
          @content;
        }
      }
    } @else {
      @media (prefers-color-scheme: $mode) {
        @content;
      }
    }
  } @else {
    [data-theme="#{$mode}"] {
      @content;
    }
  }
}

// Responsive web
$desktop: 1920px;
$mobile: 1279px;

$laptop: 1024px;
$tablet: 768px;

@mixin desktop {
  @media screen and (min-width:#{$mobile + 1}) {
    @content;
  }
}
@mixin laptop{ 
  @media screen and (min-width:#{$laptop}){ 
      @content;
  } 
}
@mixin tablet{ 
  @media screen and (min-width:#{$tablet}){ 
      @content;
  } 
}
@mixin mobile{ 
  @media screen and (max-width:#{$mobile}){ 
    @content; 
  }
}

// actions
// 버튼 또는 링크의 hover, focus, active 상태값 스타일 설정
// border-radius가 있는 경우 $raius에 추가하여 사용한다.
@mixin action($radius: null) {
  &:not(:disabled)::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @if $radius != null {
      border-radius: $radius;
    }
  }
  &:not(:disabled):hover::after {
    content: '';
    background-color: var(--ds-color-action-hover);
  }
  &:not(:disabled):focus-visible::after {
    content: '';
    background-color: var(--ds-color-action-focus);
  }
  &:not(:disabled):active::after {
    content: '';
    background-color: var(--ds-color-action-active);
  }
}