@import '../config/index.scss';

/* button */
.dsx-Button {
  --dsx-button-height: var(--dsx-scale-5);
  --dsx-button-padding-x: var(--dsx-space-4);
  --dsx-button-padding-y: var(--dsx-space-2);
  --dsx-button-font-color: var(--dsx-color-neutral-text-default);
  --dsx-button-font-size: var(--dsx-font-size-base);
  --dsx-button-border-width: var(--dsx-stroke-base);
  --dsx-button-border-color: var(--dsx-color-neutral-border-neutral);
  --dsx-button-border-radius: var(--dsx-radius-base);
  --dsx-button-background-color: transparent;
  position: relative;
  @include flex(inline-flex, center, center);
  gap: var(--dsx-space-3);
  min-height: var(--dsx-button-height);
  padding: var(--dsx-button-padding-y) var(--dsx-button-padding-x);
  color: var(--dsx-button-font-color);
  font-size: var(--dsx-button-font-size);
  vertical-align: middle;
  border-radius: var(--dsx-button-border-radius);
  background-color: var(--dsx-button-background-color);
  box-shadow: inset 0 0 0 var(--dsx-button-border-width) var(--dsx-button-border-color);
  @include interaction();
  &:disabled {
    --dsx-icon-color: var(--dsx-color-interaction-icon-inactive);
    --dsx-button-font-color: var(--dsx-color-interaction-text-inactive);
    --dsx-button-border-color: var(--dsx-color-interaction-border-disabled);
    --dsx-button-background-color: var(--dsx-color-interaction-fill-disabled);
  }
  .dsx-Icon {
    margin: 0 calc(var(--dsx-space-2) * -1);
  }

  // VARIANT
  &--primary {
    --dsx-button-font-color: var(--dsx-color-static-white);
    --dsx-button-border-color: transparent !important;
    --dsx-button-background-color: var(--dsx-color-primary-fill-default);
  }
  &--secondary {
    --dsx-button-border-color: transparent !important;
    --dsx-button-background-color: var(--dsx-color-neutral-fill-alternative);
    --dsx-icon-color: var(--dsx-color-normal-icon-default);
  }
  &--soft {
    --dsx-button-font-color: var(--dsx-color-primary-text-default);
    --dsx-button-border-color: transparent !important;
    --dsx-button-background-color: var(--dsx-color-primary-fill-neutral);
    --dsx-icon-color: var(--dsx-color-primary-icon-default);
  }
  &--ghost {
    --dsx-button-border-color: transparent !important;
    --dsx-button-background-color: transparent !important;
    &::after {
      display: none;
    }
    &:not(:disabled):hover {
      --dsx-button-font-color: var(--dsx-color-primary-text-default);
    }
  }
  &--negative {
    --dsx-button-font-color: var(--dsx-color-static-white);
    --dsx-button-border-color: transparent !important;
    --dsx-button-background-color: var(--dsx-color-negative-fill-default);
  }
  &--normal {
    --dsx-button-font-color: var(--dsx-color-static-white);
    --dsx-button-border-color: transparent !important;
    --dsx-button-background-color: var(--dsx-color-normal-fill-default);
  }
  &--outline {
    --dsx-icon-color: var(--dsx-color-neutral-icon-neutral);
  }
  &--text {
    --dsx-button-font-size: #{rem(13px)} !important;
    --dsx-button-border-color: transparent !important;
    --dsx-button-background-color: transparent !important;
    padding: 0;
    font-weight: var(--dsx-font-weight-medium);
    &::after {
      display: none;
    }
    &:not(:disabled):hover {
      --dsx-button-font-color: var(--dsx-color-primary-text-default);
    }
  }


  // SIZE
  &--small {
    --dsx-button-height: var(--dsx-scale-4);
    --dsx-button-padding-x: var(--dsx-space-3);
    --dsx-button-padding-y: var(--dsx-space-1);
    --dsx-button-font-size: var(--dsx-font-size-small);
  }
  &--medium {
    --dsx-button-height: #{rem(36px)};
    --dsx-button-padding-x: var(--dsx-space-5);
    --dsx-button-padding-y: var(--dsx-space-2);
    --dsx-button-font-size: var(--dsx-font-size-base);
  }
  &--large {
    --dsx-button-height: var(--dsx-scale-6);
    --dsx-button-padding-x: var(--dsx-space-5);
    --dsx-button-padding-y: var(--dsx-space-3);
    --dsx-button-border-radius: var(--dsx-radius-large);
    --dsx-icon-size: var(--dsx-scale-3);
    .dsx-Icon {
      margin: 0 calc(var(--dsx-space-2) * -1.5);
    }
  }
}

// 버튼 정렬
.dsx-ButtonArea {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: var(--dsx-space-3);
  margin-top: var(--dsx-space-5);
  &.dsx-align-full {
    .dsx-Button {
      flex: 1 1 0;
    }
  }
  &.dsx-align-vertical {
    flex-direction: column-reverse !important;
  }
}
