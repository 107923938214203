/* chip */
.ds-chip {
  position: relative;
  .chip-label {
    position: relative;
    @include flex(flex, center, center);
    height: 100%;
  }
  .chip-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    appearance: none;
  }
}
// 회색 배경 선택 그룹
.ds-chip-type1 {
  .chip-label {
    min-height: rem(44px);
    font-size: rem(13px);
    letter-spacing: -0.065px;
    border-radius: var(--ds-border-radius-base);
    background-color: var(--ds-color-fill-subtler);
  }
  .chip-input {
    &:checked + .chip-label {
      color: var(--ds-color-primary);
      font-weight: var(--ds-font-weight-medium);
      border: 1px solid currentColor;
    }
  }
}
// 버튼 모양 선택 그룹
.ds-chip-type2 {
  .chip-label {
    min-height: rem(24px);
    padding: 4px 8px;
    color: var(--ds-color-text-strong);
    font-size: var(--ds-font-size-sm);
    line-height: var(--ds-line-height-fit);
    border: 1px solid var(--ds-color-border-subtle);
    border-radius: var(--ds-border-radius-base);
  }
  .chip-input {
    &:checked + .chip-label {
      color: var(--ds-color-static-white);
      background-color: var(--ds-color-primary);
      border-color: transparent;
    }
  }
}

// chip 정렬
.ds-chip-group {
  --ds-chip-group-column: 33.3%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-right: -8px;
  .ds-chip {
    margin-right: 8px;
    width: calc(var(--ds-chip-group-column) - 8px);
  }
  &.column-2 {
    --ds-chip-group-column: 50%;
  }
  &.column-4 {
    --ds-chip-group-column: 25%;
  }
}