@import '../config/index.scss';

.userselect-popover {
  width: rem(320px);
  padding: var(--dsx-space-5);
  .dsx-ButtonArea {
    margin-top: var(--dsx-space-4);
  }
}
.userselect-field {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: rem(200px);
  margin-top: var(--dsx-space-4);
  padding: 12px;
  border: 1px solid var(--dsx-color-neutral-border-alternative);
  border-radius: var(--dsx-radius-xlarge);
  .field-empty {
    height: 100%;
  }
  .field-head {
    padding-bottom: 11px;
    border-bottom: 1px solid var(--dsx-color-neutral-border-alternative);
    font-weight: var(--dsx-font-weight-semibold);
  }
  .field-body {
    height: 100%;
    overflow-y: auto;
    .dsx-CheckboxGroup {
      align-items: stretch;
      .dsx-Checkbox:has(:checked) {
        color: var(--dsx-color-primary-text-default);
        font-weight: var(--dsx-font-weight-medium);
      }
      .dsx-Checkbox:hover {
        background-color: var(--dsx-color-primary-surface-default);
      }
    }
  }
  .dsx-Checkbox {
    margin: 0;
    padding: 1px 3px;
  }
}

.userselect-panel {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: rem(130px);
  margin-top: 12px;
  padding: 20px;
  border-radius: 20px;
  background-color: #f8f9fb;
  overflow-y: auto;
  .tag-user {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    height: rem(40px);
    padding: 0 8px 0 12px;
    color: var(--dsx-color-neutral-text-default);
    font-weight: var(--dsx-font-weight-medium);
    border: 1px solid var(--dsx-color-normal-border-neutral);
    border-radius: var(--dsx-radius-xlarge);
    .dsx-IconButton {
      color: var(--dsx-color-normal-icon-default);
    }
  }
}
