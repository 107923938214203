@import '../config/index.scss';

/* Text */
.dsx-Heading {
  &:where(strong) {
    display: block;
  }
  &--display1 {
    font-size: var(--dsx-font-size-display1);
    line-height: var(--dsx-line-height-heading);
    letter-spacing: var(--dsx-letter-spacing-display);
  }
  &--display2 {
    font-size: var(--dsx-font-size-display2);
    line-height: var(--dsx-line-height-heading);
    letter-spacing: var(--dsx-letter-spacing-display);
  }
  &--heading1 {
    font-size: var(--dsx-font-size-heading1);
    line-height: var(--dsx-line-height-heading);
    letter-spacing: var(--dsx-letter-spacing-heading);
  }
  &--heading2 {
    font-size: var(--dsx-font-size-heading2);
    line-height: var(--dsx-line-height-heading);
    letter-spacing: var(--dsx-letter-spacing-heading);
  }
  &--heading3 {
    font-size: var(--dsx-font-size-heading3);
    line-height: var(--dsx-line-height-heading);
    letter-spacing: var(--dsx-letter-spacing-heading);
  }
  &--title1 {
    font-size: var(--dsx-font-size-title1);
    line-height: var(--dsx-line-height-heading);
    letter-spacing: var(--dsx-letter-spacing-heading);
  }
  &--title2 {
    font-size: var(--dsx-font-size-title2);
    line-height: var(--dsx-line-height-heading);
  }
  &--title3 {
    font-size: var(--dsx-font-size-title3);
    line-height: var(--dsx-line-height-heading);
  }
  &--body1 {
    font-size: var(--dsx-font-size-body1);
  }
  &--body2 {
    font-size: var(--dsx-font-size-body2);
  }
  &.is-required {
    display: inline-flex;
    gap: 4px;
    &::after {
      content: '필수입력항목';
      width: 0.3125rem;
      height: 0.3125rem;
      border-radius: 100%;
      background-color: var(--dsx-color-negative-fill-default);
      text-indent: -999px;
      overflow: hidden;
    }
  }
}
