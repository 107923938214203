@import '../config/index.scss';

/* select */
.dsx-Select {
  --dsx-select-combobox-height: var(--dsx-scale-5);
  --dsx-select-combobox-padding: 0 var(--dsx-space-4);
  --dsx-select-combobox-font-color: var(--dsx-color-neutral-text-default);
  --dsx-select-combobox-font-size: var(--dsx-font-size-base);
  --dsx-select-combobox-font-weight: var(--dsx-font-weight-medium);
  --dsx-select-combobox-border-width: var(--dsx-stroke-base);
  --dsx-select-combobox-border-color: var(--dsx-color-neutral-border-alternative);
  --dsx-select-combobox-border-radius: var(--dsx-radius-base);
  --dsx-select-combobox-background-color: var(--dsx-color-neutral-surface-default);
  --dsx-icon-size: #{rem(16px)};
  position: relative;
  display: inline-flex;
  min-width: rem(124px);
  vertical-align: middle;

  // combobox
  &-combobox {
    display: flex;
    align-items: center;
    gap: var(--dsx-space-3);
    width: 100%;
    height: var(--dsx-select-combobox-height);
    padding: var(--dsx-select-combobox-padding);
    color: var(--dsx-select-combobox-font-color);
    font-size: var(--dsx-select-combobox-font-size);
    font-weight: var(--dsx-select-combobox-font-weight);
    border-radius: var(--dsx-select-combobox-border-radius);
    box-shadow: inset 0 0 0 var(--dsx-select-combobox-border-width) var(--dsx-select-combobox-border-color);
    background-color: var(--dsx-select-combobox-background-color);
    transition: background-color var(--dsx-transition-base);
    &:not(.is-readonly):disabled {
      --dsx-select-combobox-font-color: var(--dsx-color-interaction-text-disabled);
      --dsx-select-combobox-border-color: var(--dsx-color-interaction-border-disabled) !important;
      --dsx-select-combobox-background-color: var(--dsx-color-interaction-fill-disabled);
    }
    &.is-placeholder .dsx-Select-value {
      color: var(--dsx-color-interaction-text-inactive);
      font-weight: var(--dsx-font-weight-regular);
    }
  }
  &-value {
    @include ellipsis(1);
  }
  &-arrow {
    margin-left: auto;
    margin-right: calc(var(--dsx-space-2) * -1);
  }

  &.is-invalid &-combobox {
    --dsx-select-combobox-border-color: var(--dsx-color-negative-border-default) !important;
  }

  // SELECT STATE
  &[data-state='show'] {
    .dsx-Select-combobox {
      --dsx-select-combobox-border-color: var(--dsx-color-primary-border-default);
    }
  }

  // VARIANT
  &--filled {
    --dsx-select-combobox-border-color: transparent;
    --dsx-select-combobox-background-color: #f8f9fb;
  }
  &--ghost {
    --dsx-select-combobox-border-width: 0;
    --dsx-select-combobox-padding: 0;
    min-width: auto;
    .dsx-Select-combobox {
      min-width: auto;
      padding-right: var(--dsx-space-2);
      &::after {
        display: none;
      }
      &:not(:disabled):hover {
        color: var(--dsx-color-primary-text-default);
        background: none;
      }
    }
  }

  // SIZE
  &--small {
    --dsx-select-combobox-height: var(--dsx-scale-4);
    --dsx-select-combobox-padding: 0 var(--dsx-space-3);
    --dsx-select-combobox-font-size: var(--dsx-font-size-small);
  }
  &--large {
    --dsx-select-combobox-height: var(--dsx-scale-6);
    --dsx-select-combobox-padding: 0 var(--dsx-space-4);
    --dsx-select-combobox-border-radius: var(--dsx-radius-large);
    --dsx-icon-size: #{rem(20px)};
  }
  &--large &-arrow {
    margin-right: calc(var(--dsx-space-2) * -1.5);
  }
  &--xlarge {
    --dsx-select-combobox-height: var(--dsx-scale-7);
    --dsx-select-combobox-padding: 0 var(--dsx-space-5);
    --dsx-select-combobox-border-radius: var(--dsx-radius-xlarge);
    --dsx-select-combobox-font-size: var(--dsx-font-size-body2);
    --dsx-icon-size: #{rem(20px)};
  }

  // LISTBOX
  &-listbox {
    --dsx-select-listbox-padding: var(--dsx-space-2);
    --dsx-select-option-height: var(--dsx-scale-5);
    display: flex;
    flex-direction: column;
    gap: var(--dsx-space-2);
    width: fit-content;
    min-width: 100%;
    max-height: rem(210px);
    margin: 4px 0;
    overflow-y: auto;
    padding: calc(var(--dsx-select-listbox-padding) - var(--dsx-stroke-base));
    border-radius: var(--dsx-radius-xxlarge);
    border: var(--dsx-stroke-base) solid var(--dsx-color-neutral-border-alternative);
    background: var(--dsx-color-neutral-surface-default);
    box-shadow: var(--dsx-shadow-3);
    @include scrollbar();
  }

  // LISTBOX OPTION
  &-option {
    flex: 0 0 auto;
    position: relative;
    @include flex(flex, flex-start, center);
    gap: var(--dsx-space-2);
    width: 100%;
    height: var(--dsx-select-option-height);
    padding: 0 32px 0 var(--dsx-space-3);
    color: var(--dsx-color-neutral-text-default);
    font-weight: var(--dsx-font-weight-medium);
    text-align: left;
    line-height: 1.3;
    white-space: nowrap;
    border-radius: var(--dsx-radius-large);
    &[tabindex='0'] {
      cursor: pointer;
    }
    @include interaction();
    &[aria-selected='true'] {
      color: var(--dsx-color-primary-fill-default);
    }
    &.is-current {
      background-color: #000;
    }
  }

  &-listbox--xlarge {
    --dsx-select-listbox-padding: var(--dsx-space-3);
    --dsx-select-option-height: var(--dsx-scale-6);
  }
}
