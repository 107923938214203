.ds-link {
  @include flex(flex, center, center);
  gap: 4px;
  // font-size: var(--ds-font-size-sm);
  // font-weight: var(--ds-font-weight-medium);
  padding: 0;
  border: 0;
  border-radius: 0;
  background: none;
  appearance: none;
  .ds-icon {
    width: rem(16px);
  }
}

.link-group {
  display: flex;
  gap: 20px;
}